import { useContext } from 'react';

import {
	incomeFreeSector,
	incomeFromCommonArea,
	incomeSocial,
	totalIncomeSecondYear,
} from '../../../reducers/calculations/incomeCalculation';
import { totalHousesAreaBvo } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CostColumn } from '../../lib/costrow/CostColumn';
import { CostInput } from '../../lib/costrow/CostInput';
import { CostRow } from '../../lib/costrow/CostRow';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { LossIcon } from '../../lib/custom-icons/LossIcon';
import { SolarPanelIcon } from '../../lib/custom-icons/SolarPanelIcon';
import { Step } from '../Step';
import { FINANCE_STEP } from '../s8-financing/FinancingStep';
import {
	rentalIncomeFreeSectorPerMonth,
	rentalIncomeSocialPerMonth,
	totalFixedContract,
	totalFullRate,
	totalReducedRate,
	incomeFirstYearWithServiceCosts,
	totalIncomeFirstYear,
	incomeFirstYearOnlyContracts, incomeFirstYearContractsAndRenting, incomeFirstYearOnlyRenting,
} from '../../../reducers/incomeReducer';
import ShowConditional from '../../lib/ShowConditional';
import { CalculationTableRowTerm } from '../../lib/calctable/CalculationTableRowTerm';
import { CalculationTableRow } from '../../lib/calctable/CalculationTableRow';
import { capitalRequired, totalMortgage } from '../../../reducers/financingReducer';
import { totalServiceCostsReal } from '../../../reducers/serviceReducer';
import {
	managementFreeSectorCosts,
	managementSocialCosts,
	totalBuyCostsSocialAndFreeSectorOnly,
} from '../../../reducers/investingReducer';

export const INCOME_STEP = 'income';

function setIncomeValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'income/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

export function IncomeStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	const { noHouses } = state.housingReducer;

	return (
		<Step
			name='Inkomsten'
			id={INCOME_STEP}
			wider={true}
			description='Bepaal de vaste contracten en dagdeelverhuur per te verhuren ruimte'
			icon='bi:cash-coin'
			showContent={state.stepReducer.step === INCOME_STEP}
			nextStepValidations={[
				{ valid: noHouses.freeSector == 0 || rentalIncomeFreeSectorPerMonth(state) >= 0, error: 'Huur gebouw moet ingevuld zijn' },
				{ valid: noHouses.freeSector == 0 || state.incomeReducer.rentalIncomeFreeSectorInflationPerYear >= 0, error: 'Huurstijging gebouw moet ingevuld zijn' },
			]}
			nextStep={FINANCE_STEP}
			thisStep={INCOME_STEP}
			summaryComponent={
				<div style={{ display: 'grid', gridTemplateColumns: '0.5fr' }}>
					<div />
					<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)', fontWeight: 'bold' }}>
						Jaar 1
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Vast contract / jaar</span>
						<div>
							<span className='bold-value'>
								<FormattedNumber
									value={state.housingReducer.rentableRooms.reduce((sum, r) => sum + r.yearlyContractIncome, 0)}
									prefix='€ '
									className='bold-value'
									decimalScale={0} />
							</span>
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Voltarief</span>
						<div>
							<span className='bold-value'>
								<FormattedNumber
									value={state.housingReducer.rentableRooms.reduce((sum, r) => sum + (r.amountOfHoursFullPrice * r.pricePerHourFull), 0)}
									prefix='€ '
									className='bold-value'
									decimalScale={0} />
							</span>
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Kortingstarief</span>
						<span className='bold-value'>
							<FormattedNumber
								value={state.housingReducer.rentableRooms.reduce((sum, r) => sum + (r.amountOfHoursReducedPrice1 * r.pricePerHourReduced1) + (r.amountOfHoursReducedPrice2 * r.pricePerHourReduced2), 0)}
								prefix='€ '
								className='bold-value'
								decimalScale={0} />
						</span>
					</div>
				</div>
			}>
			<div>
				{state.housingReducer.rentableRooms.map((r, idx) => {
					return <div>
						<h3>{r.name}</h3>
						<CalculationTableRow>
							<div />
							<div />

							<CalculationTableRowTerm
								sign=" "
								value={r.yearlyContractIncome}
								onValueChange={({ floatValue }) => dispatch({ type: 'housing/rentableRoomPropertySet', index: idx, name: 'yearlyContractIncome', value: floatValue })}
								unit='vast contract / jaar'
								wide={true}
								prefix='€ '
								decimals={0}
								editable={true} />
						</CalculationTableRow>
						<br />
						<CalculationTableRow>
							<CalculationTableRowTerm
								sign=""
								value={r.amountOfHoursFullPrice}
								onValueChange={({ floatValue }) => dispatch({ type: 'housing/rentableRoomPropertySet', index: idx, name: 'amountOfHoursFullPrice', value: floatValue })}
								unit='uren / jaar'
								wide={true}
								decimals={0}
								editable={true} />
							<CalculationTableRowTerm
								sign="*"
								value={r.pricePerHourFull}
								onValueChange={({ floatValue }) => dispatch({ type: 'housing/rentableRoomPropertySet', index: idx, name: 'pricePerHourFull', value: floatValue })}
								unit='per uur'
								prefix='€ '
								wide={true}
								decimals={2}
								editable={true} />
							<CalculationTableRowTerm
								sign="="
								value={r.amountOfHoursFullPrice * r.pricePerHourFull}
								unit='voltarief'
								wide={true}
								prefix='€ '
								decimals={2}
								editable={false} />
						</CalculationTableRow>
						<CalculationTableRow>
							<CalculationTableRowTerm
								sign=""
								value={r.amountOfHoursReducedPrice1}
								onValueChange={({ floatValue }) => dispatch({ type: 'housing/rentableRoomPropertySet', index: idx, name: 'amountOfHoursReducedPrice1', value: floatValue })}
								unit='uren / jaar'
								wide={true}
								decimals={0}
								editable={true} />
							<CalculationTableRowTerm
								sign="*"
								value={r.pricePerHourReduced1}
								onValueChange={({ floatValue }) => dispatch({ type: 'housing/rentableRoomPropertySet', index: idx, name: 'pricePerHourReduced1', value: floatValue })}
								unit='per uur'
								prefix='€ '
								wide={true}
								decimals={2}
								editable={true} />
							<CalculationTableRowTerm
								sign="="
								value={r.amountOfHoursReducedPrice1 * r.pricePerHourReduced1}
								unit='kortingstarief 1'
								wide={true}
								prefix='€ '
								decimals={2}
								editable={false} />
						</CalculationTableRow>
						<CalculationTableRow>
							<CalculationTableRowTerm
								sign=""
								value={r.amountOfHoursReducedPrice2}
								onValueChange={({ floatValue }) => dispatch({ type: 'housing/rentableRoomPropertySet', index: idx, name: 'amountOfHoursReducedPrice2', value: floatValue })}
								unit='uren / jaar'
								wide={true}
								decimals={0}
								editable={true} />
							<CalculationTableRowTerm
								sign="*"
								value={r.pricePerHourReduced2}
								onValueChange={({ floatValue }) => dispatch({ type: 'housing/rentableRoomPropertySet', index: idx, name: 'pricePerHourReduced2', value: floatValue })}
								unit='per uur'
								prefix='€ '
								wide={true}
								decimals={2}
								editable={true} />
							<CalculationTableRowTerm
								sign="="
								value={r.amountOfHoursReducedPrice2 * r.pricePerHourReduced2}
								unit='kortingstarief 2'
								wide={true}
								prefix='€ '
								decimals={2}
								editable={false} />
						</CalculationTableRow>
						<div className="hor-separator" />
					</div>;
				})}
				<div className="col-2" style={{ paddingTop: '24px' }}>
					<div>
						<h4>Prijsindexatie</h4>
						<CalculationTableRowTerm
							sign=""
							value={state.incomeReducer.rentalIncomeFreeSectorInflationPerYear}
							onValueChange={({ floatValue }) => dispatch({ type: 'income/setValue', name: 'rentalIncomeFreeSectorInflationPerYear', value: floatValue })}
							unit='% indexatie per jaar'
							wide={true}
							decimals={2}
							editable={true} />
					</div>
				</div>
			</div>
		</Step>
	);
}
