import { useContext } from 'react';
import { BouwCalculatorReducerContext } from '../reducers/StepsStoreProvider';
import { RESULT_STEP } from './steps/s10-results/ResultSection';
import ShowConditional from './lib/ShowConditional';
import Indicators from './steps/s10-results/Indicators';

export function Sidebar() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	return (
		<aside style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
			<div style={{ position: 'sticky', top: 'var(--u-24)' }}>
				<div style={{ height: '20px', borderRadius: '10px', overflow: 'hidden', width: '100%', background: '#fff', boxShadow: '0 1px 4px inset rgba(0, 0, 0, 0.1)', border: '1px solid var(--col-grey-200)' }}>
					<div style={{ height: '20px', width: Math.ceil((state.stepReducer.seenSteps.size / 9) * 100) + '%', transition: 'width 0.3s ease', background: 'var(--col-primary-600)' }} />
				</div>

				<ShowConditional condition={(state.stepReducer.seenSteps ?? new Set()).has(RESULT_STEP)}>
					<aside style={{ marginTop: 'var(--u-16)', padding: 'var(--u-12) var(--u-16)' }} className='card full-w bg-white shadow p-4 px-6 rounded-md sticky top-6'>
						<Indicators />
					</aside>
				</ShowConditional>
			</div>
		</aside>
	);
}

