import '@fontsource/open-sans/latin.css';

import { useState } from 'react';

import logo from '../assets/Platform31_logo.png';
import { useFindSectionsForScrollSpy } from '../lib/hooks/useFindSectionsForScrollSpy';
import { useScrollSpy } from '../lib/hooks/useScrollSpy';
import { StepsStoreProvider } from '../reducers/StepsStoreProvider';
import { Sidebar } from './Sidebar';
import { ResultSection } from './steps/s10-results/ResultSection';
import { FinancingStep } from './steps/s8-financing/FinancingStep';
import { ProjectStep } from './steps/s1-project/ProjectStep';
import { IncomeStep } from './steps/s7-income/IncomeStep';
import { CostsStep } from './steps/s5-costs/CostsStep';
import { ServiceCostStep } from './steps/s6-servicecost/ServiceCostStep';
import { TerrainStep } from './steps/s4-terrain/TerrainStep';
import { UnitLayoutStep } from './steps/s2-units/UnitLayoutStep';
import { ValuationStep } from './steps/s9-value/ValuationStep';
import ModalWrapper from './ModalWrapper';
import SuggestReusingState from './SuggestReusingState';
import FaqModal from './faq/FaqModal';
import AboutModal from './about/AboutModal';
import FeedbackModal from './feedback/FeedbackModal';

function App() {
	const [ activeSectionStepId, setActiveSectionStepId ] = useState();
	const [ showAboutUs, setShowAboutUs ] = useState(false);
	const [ showFaq, setShowFaq ] = useState(false);
	const [ showFeedbackModal, setShowFeedbackModal ] = useState(false);
	const { elements: sectionElements } = useFindSectionsForScrollSpy('main section h2', 'H2');
	useScrollSpy(setActiveSectionStepId, 'h2');

	return <div className='app'>
		<ModalWrapper>
			<AboutModal open={showAboutUs} onRequestClose={() => setShowAboutUs(false)} />
			<FaqModal open={showFaq} onRequestClose={() => setShowFaq(false)} />
			<FeedbackModal open={showFeedbackModal} onRequestClose={() => setShowFeedbackModal(false)} />

			<header className='topbar'>
				<div className='topbar-content'>
					<div>
						<img src={logo} alt='Logo' style={{ transform: 'translate(-18px, 0)' }} />
					</div>
					<nav>
						<a href="#" onClick={() => setShowAboutUs(true)}>Over ons</a>
						<a href="#" onClick={() => setShowFaq(true)}>FAQ</a>
						<a href="#" onClick={() => setShowFeedbackModal(true)}>Feedback</a>
					</nav>
				</div>
			</header>
			<main>
				<h1 style={{ fontSize: '22pt' }}>Haalbaarheidsscan Ontmoetingsruimte</h1>

				<div className="two-col-layout">
					<div className="column">
						<div className="hero-text">
							<p>Wilt u bij uw wooninitiatief ook een ontmoetingsruimte voor de buurt realiseren? Check hier de haalbaarheid!</p>
							<p>De scan helpt u bij het opstellen van een businesscase voor uw ontmoetingsruimte. Op basis van de projectinformatie krijgt u overzicht in de kosten van uw ontmoetingsruimte, de opbrengsten van de te verhuren ruimten en de benodigde financieringen. Mocht u deze niet sluitend krijgen, dan geeft de scan inzicht aan welke knoppen u kunt draaien.</p>
							<p>Tip: lees eerst de <a href="#" onClick={e => setShowFaq(true)}>FAQ</a>, voordat u de haalbaarheidsscan invult</p>
						</div>
					</div>
					<aside className="sidebar desktop-only" />
				</div>

				<br /><br />

				<div className='two-col-layout'>
					<StepsStoreProvider>
						<div className='column'>
							<SuggestReusingState />
							<ProjectStep />
							<UnitLayoutStep />
							<TerrainStep />
							<CostsStep />
							<ServiceCostStep />
							<IncomeStep />
							<FinancingStep />
							<ValuationStep />
							<ResultSection />
						</div>
						<Sidebar elements={sectionElements} activeId={activeSectionStepId} />
					</StepsStoreProvider>
				</div>
			</main>
		</ModalWrapper>
	</div>;
}

export default App;
