import { createSelector } from 'reselect';
import { totalUsableSpace } from './housingReducer';
import { totalServiceCosts } from './serviceReducer';


export function incomeReducer(state, action) {
	switch (action.type) {
	case 'income/setValue': {
		return {
			...state,
			incomeReducer: {
				...state.incomeReducer,
				[action.name]: action.value,
			},
		};
	}
	case 'income/setExplotationTermPeriodYears': {

		return {
			...state,
			incomeReducer: {
				...state.incomeReducer,
				explotationTermPeriodYears: (action.value != null || action.value != 0) ? action.value : 1,
			},
			financingReducer: {
				...state.financingReducer,
				mortgage: {
					...state.financingReducer.mortgage,
					years: (action.value != null || action.value != 0) ? action.value : 1,
					periods: (action.value != null || action.value != 0) ? action.value : 1,
				},
				// softLoans: {
				// 	...state.financingReducer.softLoans,
				// 	years: action.value,
				// 	periods: action.value,
				// },
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const initialIncomeReducer = {
	incomeReducer: {
		rentalIncomeSocialPerMonth: 0,
		rentalIncomeSocialInflationPerYear: 0,

		rentalIncomeFreeSectorPerMonth: 0,
		rentalIncomeFreeSectorInflationPerYear: 2,

		rentalIncomeCommonAreaPerDayPart: 0,
		rentalIncomeCommonAreaTimesPerYear: 0,
		rentalIncomeCommonAreaInflationPerYear: 0,

		energyPerformanceFeePerM2VVoPerHouse: 0,
		energyPerformanceFeePerM2VVoInflationPerYear: 0,

		lossToVacantHousesPercentOfTheRentalIncomeServiceCostsAndEnergyPerformanceFee: 0,

		explotationFromYear: new Date().getFullYear() + 1,
		explotationTermPeriodYears: 20,
	},
};

export const income = (state) => state.incomeReducer;
export const housing = (state) => state.housingReducer;
export const service = (state) => state.serviceReducer;
// export const incomePerRentalHouses = createSelector(
// 	income,
// 	housing,
// 	(income, housing) =>
// 		income.capitalRequirementInitialInvestment / (housing.noHouses.social + housing.noHouses.freeSector)
// );

export const totalFixedContract = createSelector(housing, (housing) => housing.rentableRooms.reduce((sum, r) => sum + (r.yearlyContractIncome != null ? r.yearlyContractIncome : 0), 0));

export const totalFullRate = createSelector(housing, (housing) => housing.rentableRooms.reduce((sum, r) => sum + (r.amountOfDayPartsFullPrice * r.pricePerDayPartFull) + (r.amountOfHoursFullPrice * r.pricePerHourFull), 0));

export const totalReducedRate = createSelector(housing, (housing) => housing.rentableRooms.reduce((sum, r) => sum + (r.amountOfDayPartsReducedPrice * r.pricePerDayPartReduced) + (r.amountOfHoursReducedPrice1 * r.pricePerHourReduced1) + (r.amountOfHoursReducedPrice2 * r.pricePerHourReduced2), 0));

// filter only the rooms that have a yearly contract
// add up all those incomes together
export const incomeFirstYearOnlyContracts = createSelector(housing, (housing) => housing.rentableRooms.filter(room => room.yearlyContractIncome != null && room.yearlyContractIncome > 0
	&& (room.amountOfHoursFullPrice == 0 && room.amountOfHoursReducedPrice1 == 0 && room.amountOfHoursReducedPrice2 == 0 && room.amountOfDayPartsFullPrice == 0 && room.amountOfDayPartsReducedPrice == 0))
	.reduce((sum, i) => sum + i.yearlyContractIncome, 0));

export const incomeFirstYearContractsAndRenting = createSelector(housing, (housing) => housing.rentableRooms
	.filter(room => room.yearlyContractIncome != null && room.yearlyContractIncome > 0 && (room.amountOfHoursFullPrice > 0 || room.amountOfHoursReducedPrice1 > 0 || room.amountOfHoursReducedPrice2 > 0 || room.amountOfDayPartsFullPrice > 0 || room.amountOfDayPartsReducedPrice > 0))
	.reduce((sum, i) => sum + i.yearlyContractIncome + (i.amountOfDayPartsFullPrice * i.pricePerDayPartFull) + (i.amountOfDayPartsReducedPrice * i.pricePerDayPartReduced)
		+ (i.amountOfHoursFullPrice * i.pricePerHourFull) + (i.amountOfHoursReducedPrice1 * i.pricePerHourReduced1) + (i.amountOfHoursReducedPrice2 * i.pricePerHourReduced2), 0));

// filter only the rooms that don't have a yearly income BUT are rented out by daypart/hour
// add up those incomes and substract from them the service costs for the room
export const incomeFirstYearOnlyRenting = createSelector(housing, totalUsableSpace, totalServiceCosts, (housing, totalUsableSpace, totalServiceCosts) => housing.rentableRooms
	.filter(room => (room.yearlyContractIncome == null || room.yearlyContractIncome == 0) && ((room.amountOfHoursFullPrice > 0 && room.amountOfHoursReducedPrice1 > 0 && room.amountOfHoursReducedPrice2 > 0) || (room.amountOfDayPartsFullPrice > 0 && room.amountOfDayPartsReducedPrice > 0)))
	.reduce((sum, i) => sum + (i.amountOfDayPartsFullPrice * i.pricePerDayPartFull) + (i.amountOfDayPartsReducedPrice * i.pricePerDayPartReduced)
		+ (i.amountOfHoursFullPrice * i.pricePerHourFull) + (i.amountOfHoursReducedPrice1 * i.pricePerHourReduced1) + (i.amountOfHoursReducedPrice2 * i.pricePerHourReduced2) - (i.GO / totalUsableSpace) * totalServiceCosts, 0));

export const totalIncomeFirstYear = createSelector(
	incomeFirstYearOnlyContracts,
	incomeFirstYearContractsAndRenting,
	incomeFirstYearOnlyRenting,
	(incomeFirstYearOnlyContracts, incomeFirstYearContractsAndRenting, incomeFirstYearOnlyRenting) => incomeFirstYearOnlyContracts + incomeFirstYearContractsAndRenting + incomeFirstYearOnlyRenting);

export const rentalIncomeSocialPerMonth = createSelector(
	housing,
	income,
	(housing, income) => income.rentalIncomeSocialPerMonth ?? ((housing.municipality?.rentExpectation?.social ?? 0) * housing.areaVvo.social)
);

export const rentalIncomeFreeSectorPerMonth = createSelector(
	housing,
	income,
	(housing, income) => income.rentalIncomeFreeSectorPerMonth ?? ((housing.municipality?.rentExpectation?.freeSector ?? 0) * housing.areaVvo.freeSector)
);

export const vacancyLoss = createSelector(
	income,
	(income) => 1 - income.lossToVacantHousesPercentOfTheRentalIncomeServiceCostsAndEnergyPerformanceFee / 100
);

// Kasstroom P6 ofwel: Canvas G24
// DEZE NODIG
export const incomeRentalSocialHousingFirstYear = createSelector(
	income,
	housing,
	vacancyLoss,
	rentalIncomeSocialPerMonth,
	(income, housing, vacancyLoss, rentalIncomeSocialPerMonth) => rentalIncomeSocialPerMonth * housing.noHouses.social * 12 * vacancyLoss
);
// DEZE NODIG
export const energyPerformanceFeeSocialHousing = createSelector(
	income,
	housing,
	(income, housing) => income.energyPerformanceFeePerM2VVoPerHouse * housing.noHouses.social * housing.areaVvo.social
);

export const energyPerformanceFeeFreeSector = createSelector(
	income,
	housing,
	(income, housing) => income.energyPerformanceFeePerM2VVoPerHouse * housing.areaVvo.freeSector * housing.noHouses.freeSector
);

export const incomeFreeSectorHousingFirstYear = createSelector(
	income,
	housing,
	vacancyLoss,
	rentalIncomeFreeSectorPerMonth,
	(income, housing, vacancyLoss, rentalIncomeFreeSectorPerMonth) => rentalIncomeFreeSectorPerMonth * housing.noHouses.freeSector * 12 * vacancyLoss
);

export const incomeCommonArea = createSelector(
	income,
	(income) => income.rentalIncomeCommonAreaPerDayPart * income.rentalIncomeCommonAreaTimesPerYear
);
