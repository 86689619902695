import { createSelector } from 'reselect';
import { income, service } from '../incomeReducer';
import {
	buildCostsFreeSectorTotal,
	managementFreeSectorCosts,
} from '../investingReducer';
import { financing, giftsAndSubsidies, totalMortgage, totalSoftLoan } from '../financingReducer';
import { pmt } from 'financial';
import { housing, totalUsableSpace } from '../housingReducer';
import { totalServiceCosts } from '../serviceReducer';

export const years = createSelector(income, (income) => {
	return Array(income.explotationTermPeriodYears + 1)
		.fill(null)
		.map((_, index) => income.explotationFromYear + index);
});

function incomeOnlyContractsForYearY(state, year) {
	return housing(state).rentableRooms.filter(room => room.yearlyContractIncome != null && room.yearlyContractIncome > 0
		&& (room.amountOfHoursFullPrice == 0 && room.amountOfHoursReducedPrice1 == 0 && room.amountOfHoursReducedPrice2 == 0 && room.amountOfDayPartsFullPrice == 0 && room.amountOfDayPartsReducedPrice == 0))
		.reduce((sum, i) => {
			return sum + i.yearlyContractIncome * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1);
		}, 0);
}

function incomeContractsAndRentingForYearY(state, year) {
	return housing(state).rentableRooms
		.filter(room => room.yearlyContractIncome != null && room.yearlyContractIncome > 0 && (room.amountOfHoursFullPrice > 0 || room.amountOfHoursReducedPrice1 > 0 || room.amountOfHoursReducedPrice2 > 0 || room.amountOfDayPartsFullPrice > 0 || room.amountOfDayPartsReducedPrice > 0))
		.reduce((sum, i) => {
			return sum + i.yearlyContractIncome * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfDayPartsFullPrice * i.pricePerDayPartFull) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfDayPartsReducedPrice * i.pricePerDayPartReduced) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfHoursFullPrice * i.pricePerHourFull) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfHoursReducedPrice1 * i.pricePerHourReduced1) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfHoursReducedPrice2 * i.pricePerHourReduced2) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1);
		}, 0);
}

function incomeOnlyRentingForYearY(state, year) {
	return housing(state).rentableRooms
		.filter(room => (room.yearlyContractIncome == null || room.yearlyContractIncome == 0) && (room.amountOfHoursFullPrice > 0 || room.amountOfHoursReducedPrice1 > 0 || room.amountOfHoursReducedPrice2 > 0 || room.amountOfDayPartsFullPrice > 0 || room.amountOfDayPartsReducedPrice > 0))
		.reduce((sum, i) => {
			return sum + (i.amountOfDayPartsFullPrice * i.pricePerDayPartFull) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfDayPartsReducedPrice * i.pricePerDayPartReduced) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfHoursFullPrice * i.pricePerHourFull) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfHoursReducedPrice1 * i.pricePerHourReduced1) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			+ (i.amountOfHoursReducedPrice2 * i.pricePerHourReduced2) * (1 + income(state).rentalIncomeFreeSectorInflationPerYear / 100) ** (year - 1)
			- (i.GO / totalUsableSpace(state)) * totalServiceCosts(state) * (1 + service(state).inflation / 100) ** (year - 1);
		}, 0);
}

export function netIncomeForYearY(state, year) {
	return incomeOnlyContractsForYearY(state, year) + incomeContractsAndRentingForYearY(state, year) + incomeOnlyRentingForYearY(state, year);
}

function calculatePmtForSoftLoan(state, sl, amount) {
	return -pmt(sl.interestRate / 100, sl.years, amount);
}

export const cashFlowFollowingYearsNew = (state) => years(state).reduce((previous, year, index) => {

	if (index == 0) {
		return [
			...previous,
			{
				year,
				total: 0 - buildCostsFreeSectorTotal(state) + totalMortgage(state) + totalSoftLoan(state) + giftsAndSubsidies(state),
				totalWithoutFinancing: 0 - buildCostsFreeSectorTotal(state),
				netIncome: 0,
				result: -buildCostsFreeSectorTotal(state),
				costs: {
					managementCosts: 0,
					groundLeaseCosts: 0,
					mortgagePayment: 0,
					softLoanPayment: 0,
				},
				mortgageLeft: totalMortgage(state),
				softLoans: {
					amount: financing(state).softLoans.map(sl => sl.amount),
					years: financing(state).softLoans.map(sl => sl.years),
				},
				softLoansLeft: totalSoftLoan(state),
			},
		];
	}

	if (index != 0) {
		const softLoansPayments = previous[index - 1].softLoans.amount.reduce((sum, sl, idx) => (sum + previous[index - 1].softLoans.years[idx] == index + 1 ? previous[index - 1].softLoans.amount[idx] : calculatePmtForSoftLoan(state, financing(state).softLoans[idx], sl)));
		const mortgagePayments = financing(state).mortgage.years == index + 1 ? previous[index - 1].mortgageLeft : -pmt(financing(state).mortgage.interestRate / 100, financing(state).mortgage.years, previous[index - 1].mortgageLeft);
		const groundLeaseCost = (state.investingReducer.groundLeaseAmount ?? 0) * Math.pow(1 + ((state.investingReducer.groundLeaseIndexation ?? 0) / 100), index - 1) * ((state.investingReducer.groundLeaseRate ?? 0) / 100);

		const newTotal = previous[index - 1].total + netIncomeForYearY(state, index) - managementFreeSectorCosts(state) - groundLeaseCost - mortgagePayments - softLoansPayments;
		const newTotalWithoutFinancing = previous[index - 1].totalWithoutFinancing + netIncomeForYearY(state, index) - managementFreeSectorCosts(state) - groundLeaseCost;

		return [
			...previous,
			{
				year,
				total: newTotal,
				totalWithoutFinancing: newTotalWithoutFinancing,
				netIncome: netIncomeForYearY(state, index),
				result: netIncomeForYearY(state, index) - managementFreeSectorCosts(state) - groundLeaseCost,
				costs: {
					managementCosts: managementFreeSectorCosts(state),
					groundLeaseCosts: groundLeaseCost,
					mortgagePayment: mortgagePayments,
					softLoanPayment: softLoansPayments,
				},
				mortgageLeft: previous[index - 1].mortgageLeft - mortgagePayments,
				softLoans: {
					amount: previous[index - 1].softLoans.amount.map((sl, idx) => (previous[index - 1].softLoans.years[idx] == index + 1 ? sl - previous[index - 1].softLoans.amount[idx] : sl - calculatePmtForSoftLoan(state, financing(state).softLoans[idx], sl))),
					years: previous[index - 1].softLoans.years,
				},
				softLoansLeft: previous[index - 1].softLoans.amount.map((sl, idx) => sl - calculatePmtForSoftLoan(state, financing(state).softLoans[idx], sl)).reduce((sum, sl) => sum + sl, 0),
			},
		];
	}
}, []);