import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
	GridComponent,
	LegendComponent,
	LegendScrollComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { useContext, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { AutoSizer } from 'react-virtualized';

import {
	capitalRequired,
	financingGap,
	giftsAndSubsidies,
	totalMortgage,
	totalSoftLoan,
} from '../../../reducers/financingReducer';
import { totalBuyCostsSocialAndFreeSectorOnly } from '../../../reducers/investingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CalculationTableRow } from '../../lib/calctable/CalculationTableRow';
import { CalculationTableRowTerm, Sign } from '../../lib/calctable/CalculationTableRowTerm';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { Step } from '../Step';
import { VALUE_STEP } from '../s9-value/ValuationStep';
import { CostRowFullWidth } from '../../lib/costrow/CostRow';
import { Alert, AlertType } from '../../lib/Alert';
import Button from '../../lib/forms/Button';
import { Icon } from '@iconify/react';
import { StyledInput } from '../../lib/forms/StyledInput';

export const FINANCE_STEP = 'finance';
echarts.use([
	PieChart,
	TitleComponent,
	TooltipComponent,
	GridComponent,
	ToolboxComponent,
	LegendComponent,
	LegendScrollComponent,
	VisualMapComponent,
	SVGRenderer,
]);

// const colorRange = [ '#312D81', '#3730A3', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC', '#E0E7FF' ];
// const colorRange = [ 'var(--col-primary-100)', 'var(--col-primary-300)', 'var(--col-primary-500)', 'var(--col-primary-700)', 'var(--col-primary-900)' ];
const colorRange = [ '#164e63', '#0e7490', '#06b6d4', '#67e8f9', '#a5f3fc', '#cffafe', '#155e75' ];

export function FinancingStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	const pieChart = useMemo(() => {
		return [
			giftsAndSubsidies(state) == 0 ? null : {
				name: 'Giften & subsidies',
				value: giftsAndSubsidies(state),
				itemStyle: {
					color: colorRange[0],
				},
			},
			{
				name: 'Hypotheek',
				value: totalMortgage(state),
				itemStyle: {
					color: colorRange[1],
				},
			},
			{
				name: 'Nog te financieren',
				value: financingGap(state),
				itemStyle: {
					color: colorRange[2],
				},
			},
			...state.financingReducer.softLoans.map((sl, idx) => ({
				name: sl.name,
				value: sl.amount,
				itemStyle: {
					color: colorRange[(idx + 3) % colorRange.length],
				},
			})),
		].filter(x => x != null);

		// eslint-disable-next-line
	}, [ state.financingReducer.softLoans, giftsAndSubsidies(state), totalMortgage(state), financingGap(state) ]);

	return (
		<Step
			name='Financiering'
			id={FINANCE_STEP}
			description='Bepaal hoe het ontmoetingsruimte gefinancierd gaat worden'
			icon='bi:cash-coin'
			showContent={state.stepReducer.step === FINANCE_STEP}
			readyForNextStep={true}
			nextStep={VALUE_STEP}
			thisStep={FINANCE_STEP}
			summaryComponent={
				<div className="col-2">
					<div>
						<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Kapitaalbehoefte</div>
								<div>
									<FormattedNumber
										value={totalBuyCostsSocialAndFreeSectorOnly(state)}
										prefix='€ '
										className='bold-value'
										decimalScale={0}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Giften en subsidies</div>
								<div>
									<FormattedNumber
										className='bold-value'
										value={giftsAndSubsidies(state)}
										prefix='€ '
										decimalScale={0}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--u-4)' }}>
								<div>Hypotheek</div>
								<div style={{ textAlign: 'right' }}>
									<FormattedNumber
										className='bold-value'
										value={totalMortgage(state)}
										prefix='€ '
										decimalScale={0} />
									<br />
									<small>
										<FormattedNumber
											value={state.financingReducer.mortgage.interestRate}
											decimalScale={2}
											suffix='%' />{' '}
										rente voor {state.financingReducer.mortgage.years} jaar
									</small>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--u-4)' }}>
								<div>Achtergestelde leningen</div>
								<div style={{ textAlign: 'right' }}>
									<FormattedNumber
										className='bold-value'
										value={totalSoftLoan(state)}
										prefix='€ '
										decimalScale={0}
									/>
								</div>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Nog te financieren</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={financingGap(state)}
									prefix='€ '
									decimalScale={0} />
							</div>
						</div>
					</div>
					<div>
						<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							{state.financingReducer.softLoans.map((sl, idx) => {
								return <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--u-4)' }}>
									<div>{sl.name}</div>
									<div style={{ textAlign: 'right' }}>
										<FormattedNumber
											className='bold-value'
											value={sl.amount}
											prefix='€ '
											decimalScale={0} />
										<br />
										<small>
											<FormattedNumber
												value={sl.interestRate}
												decimalScale={2}
												suffix='%' />{' '}
										rente voor {sl.years} jaar
										</small>
									</div>
								</div>;
							})
							}
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--u-4)' }}>
							<div>Achtergestelde leningen</div>
							<div style={{ textAlign: 'right' }}>
								<FormattedNumber
									className='bold-value'
									value={totalSoftLoan(state)}
									prefix='€ '
									decimalScale={0}
								/>
							</div>
						</div>
					</div>
				</div>
			}>
			<div>
				<CalculationTableRow title='Giften en subsidies'>
					<CalculationTableRowTerm
						value={capitalRequired(state)}
						unit='kapitaalbehoefte'
						decimals={0}
						prefix='€ '
						wide={true} />
					<CalculationTableRowTerm
						sign='-'
						value={state.financingReducer.giftsAndSubsidies}
						id='giftsAndSubsidies'
						unit='te ontvangen'
						decimals={0}
						prefix='€ '
						wide={true}
						editable={true}
						onValueChange={({ floatValue }, { event }) => {
							if (event) {
								dispatch({
									type: 'financing/setValue',
									name: 'giftsAndSubsidies',
									value: floatValue,
								});
							}
						}} />
					<CalculationTableRowTerm
						sign='='
						value={capitalRequired(state) - (state.financingReducer.giftsAndSubsidies ?? 0)}
						unit='over'
						wide={true}
						prefix='€ '
						decimals={0}
						editable={false} />
				</CalculationTableRow>

				<div className="hor-separator" />

				<CalculationTableRow title='Hypotheek'>
					{state.financingReducer.mortgage.percentage > 50 && <CostRowFullWidth>
						<Alert
							type={AlertType.Warning}
							text="De hypotheek verhogen maakt een project moeilijker te financiëren. Probeer eerst andere maatregelen te nemen, zoals het verhogen van de huur, of verlagen van kosten." />
					</CostRowFullWidth>}
					<CalculationTableRowTerm
						value={capitalRequired(state)}
						unit='kapitaalbehoefte'
						decimals={0}
						prefix='€ '
						wide={true} />
					<CalculationTableRowTerm
						sign='*'
						value={state.financingReducer.mortgage.percentage}
						unit='te lenen'
						decimals={0}
						suffix='%'
						wide={true}
						editable={true}
						onValueChange={({ floatValue }, { event }) => {
							if (event) {
								dispatch({
									type: 'financing/setMortgageValue',
									name: 'percentage',
									value: floatValue,
								});
							}
						}} />
					<CalculationTableRowTerm
						sign="="
						value={totalMortgage(state)}
						id='mortgage'
						unit='te lenen'
						wide={true}
						prefix='€ '
						decimals={0}
						editable={false} />
				</CalculationTableRow>

				<div className="col-3" style={{ gridTemplateColumns: '2fr 1fr', paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-16)' }}>
					<div className="col-3">
						<div>
							<h4>Rente</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.mortgage.interestRate}
								decimals={2}
								textLeft={true}
								suffix='%'
								editable={true}
								wide={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setMortgageValue',
											name: 'interestRate',
											value: floatValue,
										});
									}
								}} />
						</div>

						<div>
							<h4>Looptijd</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.mortgage.years}
								decimals={0}
								unit='jaar'
								wide={true}
								editable={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setMortgageValue',
											name: 'years',
											value: floatValue,
										});
									}
								}} />
						</div>

						<div>
							<h4>Totaal aflossen</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.mortgage.redemptionPercentage}
								decimals={0}
								suffix='%'
								textLeft={true}
								wide={true}
								editable={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setMortgageValue',
											name: 'redemptionPercentage',
											value: floatValue,
										});
									}
								}} />
						</div>
					</div>
				</div>

				<div className='hor-separator' />

				<CalculationTableRow title='Achtergestelde leningen' helpText='Dit zijn &apos;zachte&apos; leningen en crowdfundingleningen die achtergesteld zijn aan de hypotheek, dit wil zeggen dat de uitleners bij een faillissement hun geld pas terugkrijgen als de hypotheekverstrekkers afbetaald zijn.' />

				{state.financingReducer.softLoans.map((sl, idx) => {
					return <div>
						<div style={{ gridTemplateColumns: '2fr 1fr', paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-16)' }}>
							<div className="col-2">
								<div>
									<h4>Naam</h4>
									<StyledInput
										value={sl.name}
										onChange={event => {
											if (event) {
												dispatch({
													type: 'financing/setSoftLoanName',
													name: idx,
													value: event.target.value,
												});
											}
										}} />
								</div>

								<div>
									<h4>Totaal</h4>
									<CalculationTableRowTerm
										value={sl.amount}
										unit='te lenen'
										decimals={0}
										prefix='€ '
										wide={true}
										editable={true}
										onValueChange={({ floatValue }, { event }) => {
											if (event) {
												dispatch({
													type: 'financing/setSoftLoanAmount',
													name: idx,
													value: floatValue,
												});
											}
										}}
									/>
								</div>
							</div>
							<div />
						</div>

						<div style={{ gridTemplateColumns: '2fr 1fr', paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-16)' }}>
							<div className="col-4">
								<div>
									<h4>Rente</h4>
									<CalculationTableRowTerm
										value={sl.interestRate}
										decimals={2}
										textLeft={true}
										suffix='%'
										editable={true}
										wide={true}
										onValueChange={({ floatValue }, { event }) => {
											if (event) {
												dispatch({
													type: 'financing/setSoftLoanInterestRate',
													name: idx,
													value: floatValue,
												});
											}
										}} />
								</div>

								<div>
									<h4>Looptijd</h4>
									<CalculationTableRowTerm
										value={sl.years}
										decimals={0}
										unit='jaar'
										editable={true}
										wide={true}
										onValueChange={({ floatValue }, { event }) => {
											if (event) {
												dispatch({
													type: 'financing/setSoftLoanYears',
													name: idx,
													value: floatValue,
												});
											}
										}} />
								</div>

								<div>
									<h4>Totaal aflossen</h4>
									<CalculationTableRowTerm
										value={sl.redemptionPercentage}
										decimals={0}
										suffix='%'
										textLeft={true}
										wide={true}
										editable={true}
										onValueChange={({ floatValue }, { event }) => {
											if (event) {
												dispatch({
													type: 'financing/setSoftLoanRedemptionPercentage',
													name: idx,
													value: floatValue,
												});
											}
										}} />
								</div>
								<div style={{ paddingTop: '20px' }}>
									<Button onClick={() => {
										dispatch({
											type: 'financing/deleteSoftLoan',
											name: idx,
										});
									}}><Icon icon="fa:trash-o" />&nbsp; Verwijderen</Button>
								</div>
							</div>
							<div />
						</div>
						<div className='hor-separator' />
					</div>;
				})}

				<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
					<Button onClick={() => dispatch({ type: 'financing/addSoftLoan' })} style={{ display: 'flex', alignItems: 'center' }}><Icon icon="fa:plus" style={{ fontSize: '0.9em' }} />&nbsp;&nbsp;<span>Voeg lening toe </span></Button>
				</div>

				<div className='hor-separator' />

				<div className='col-2'>
					<div style={{ minHeight: '180px' }}>
						<AutoSizer>
							{({ height, width }) => (
								<ReactEChartsCore
									echarts={echarts}
									option={{
										title: {
											text: 'Verdeling financiën',
											left: 'center',
											top: 0,
											textStyle: {
												color: '#999',
												fontWeight: 'normal',
												fontSize: 14,
											},
										},
										tooltip: {
											trigger: 'item',
											formatter: ({ name, value, percent }) => {
												return renderToStaticMarkup(
													<span>
														{name}:{' '}
														<span className='font-bold'>
															{parseFloat(value).toLocaleString('nl-NL', {
																style: 'currency',
																currency: 'EUR',
																maximumFractionDigits: 0,
															})}
														</span>{' '}
														({percent}%)
													</span>
												);
											},
											extraCssText: 'z-index: 9999;',
										},
										series: [
											{
												type: 'pie',
												radius: [ 20, 50 ],
												top: 'center',
												left: 0,
												itemStyle: {
													borderColor: '#fff',
													borderWidth: 1,
												},

												label: {
													position: 'outside',
													alignTo: 'edge',
													formatter: '{b}',
													lineHeight: 0,
													distanceToLabelLine: 0,
													bleedMargin: 0,
													edgeDistance: 30,
													width: 200,
												},

												labelLine: {
													length: 20,
													length2: 0,
													showAbove: true,
												},
												data: pieChart,
											},
										],
									}}
									style={{ height, width }}
								/>
							)}
						</AutoSizer>
					</div>
					<div>
						<div style={{ display: 'inline-block' }}>
							<div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={capitalRequired(state)} />
								<div style={{ paddingRight: 'var(--u-8)' }}>
									<div style={{ fontWeight: 500, color: 'var(--col-grey-700)', fontSize: 'var(--fs-11)' }}>Kapitaalbehoefte</div>
								</div>
							</div>
							<div className='mt-2'>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={state.financingReducer.giftsAndSubsidies}
									reference={{ text: 'Giften & Subsidies', id: 'giftsAndSubsidies', step: FINANCE_STEP }} />
							</div>
							<div>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={totalMortgage(state)}
									reference={{ text: 'Hypotheek', id: 'mortgage', step: FINANCE_STEP }} />
							</div>
							<div className=''>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={totalSoftLoan(state)}
									reference={{
										text: 'Achtergestelde leningen',
										id: 'softloan',
										step: FINANCE_STEP,
									}} />
							</div>
							<div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: 'var(--u-16)' }}>
								{/* <div style={{ position: 'relative', left: '-24px' }}>*/}
								<div>
									<Sign sign='-' />
								</div>
								{/* </div>*/}
								<div style={{ flex: 1, paddingBottom: '3.5px' }}>
									<div style={{ borderTop: '2px solid var(--col-grey-300)' }} />
								</div>
							</div>
							<div>
								<CalculationTableRowTerm
									wide={true}
									value={financingGap(state)}
									unit='nog te financieren'
									prefix='€ '
									decimals={0} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Step>
	);
}
