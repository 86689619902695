import { useContext, useMemo } from 'react';

import { rentableRoomsTotalGO, rentableRoomsTotal } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext, bvoToVvoMultiplier } from '../../../reducers/StepsStoreProvider';
import { CalculationTableRowTerm } from '../../lib/calctable/CalculationTableRowTerm';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { Step } from '../Step';
import Button from '../../lib/forms/Button';
import { Icon } from '@iconify/react';
import { StyledInput } from '../../lib/forms/StyledInput';
import { buildingAreaTotalBvo, commonAreaBvoMultiplier } from '../../../reducers/buildingReducer';
import { CalculationTableRow } from '../../lib/calctable/CalculationTableRow';
import { Alert } from '../../lib/Alert';
import { TERRAIN_STEP } from '../s4-terrain/TerrainStep';

export const UNIT_STEP = 'unit';

export function UnitLayoutStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);


	return (
		<Step
			name='Indeling'
			id={UNIT_STEP}
			description='Geef het oppervlakte van de algemene, niet te verhuren ruimte aan en welke ruimtes er verhuurd kunnen worden'
			icon='clarity:building-solid'
			showContent={state.stepReducer.step === UNIT_STEP}
			thisStep={UNIT_STEP}
			nextStepValidations={[
				{ valid: rentableRoomsTotal(state) > 0, error: 'Er moet minstens één verhuurbare ruimte gedefinieerd worden', type: 'rent' },
				{ valid: rentableRoomsTotalGO(state) > 0, error: 'De te verhuren oppervlakte mag niet nul zijn', type: 'rent' },
			]}
			nextStep={TERRAIN_STEP}
			summaryComponent={
				<div>
					<span className='bold-value'><FormattedNumber value={rentableRoomsTotal(state)} decimalScale={0} /></span><span> aantal verhuurbare ruimtes</span><br />
					<span className='bold-value'><FormattedNumber value={rentableRoomsTotalGO(state)} decimalScale={0} /> m²</span><span> totaal vrij verhuurbare oppervlakte</span><br />
					<span className='bold-value'><FormattedNumber value={buildingAreaTotalBvo(state)} decimalScale={0} /> m²</span><span> totaal bruto vloeroppervlak (bvo)</span>
				</div>
			}>
			<div>
				<div>
					<CalculationTableRow
						title='Algemene ruimte'
						helpText='Dit is alles wat een niet onder een huurder geschaard kan worden, maar wel gebouwd moet worden. Denk aan entree, gangen, trappen, maar ook ontmoetingsplekken etc.'>
						{/* <CalculationTableRowTerm*/}
						{/*	value={state.buildingReducer.commonAreaBvo}*/}
						{/*	unit='m² bvo'*/}
						{/*	editable={true}*/}
						{/*	onValueChange={({ floatValue }, { event }) => {*/}
						{/*		if (event) {*/}
						{/*			dispatch({*/}
						{/*				type: 'building/setCommonAreaBvo',*/}
						{/*				value: floatValue,*/}
						{/*			});*/}
						{/*		}*/}
						{/*	}} />*/}
						{/* <CalculationTableRowTerm*/}
						{/*	sign='*'*/}
						{/*	value={commonAreaBvoMultiplier(state)}*/}
						{/*	decimals={2}*/}
						{/*	editable={false}*/}
						{/*	unit="vormfactor"*/}
						{/*	initialValue={bvoToVvoMultiplier}*/}
						{/*	onValueChange={({ floatValue }, { event }) => {*/}
						{/*		if (event) {*/}
						{/*			dispatch({*/}
						{/*				type: 'building/setCommonAreaBvoMultiplier',*/}
						{/*				value: floatValue,*/}
						{/*			});*/}
						{/*		}*/}
						{/*	}} />*/}
						<CalculationTableRowTerm
							value={state.buildingReducer.commonAreaVvo}
							unit='m² gebruiksoppervlakte'
							editable={true}
							initialValue={useMemo(
								() => state.buildingReducer.commonAreaBvo * bvoToVvoMultiplier,
								[ state.buildingReducer.commonAreaBvo ]
							)}
							onValueChange={({ floatValue }, { event }) => {
								if (event) {
									dispatch({
										type: 'building/setCommonAreaVvo',
										value: floatValue,
									});
								}
							}} />
					</CalculationTableRow>
					{/* {commonAreaBvoMultiplier(state) > 1.0 && <Alert type="warning" text="Vormfactor is groter dan 100%, dit is onrealistisch. Normaal ligt dit rond de 80%." />}*/}
					{/* {commonAreaBvoMultiplier(state) < 0.7 && <Alert type="warning" text="Vormfactor is kleiner dan 70%, dit is wellicht te pessimistisch ingeschat. Normaal ligt dit rond de 80%." />}*/}
					<br /><br />
				</div>

				<div>
					<h3>Verhuurbare ruimtes</h3><br />

					<div style={{ display: 'grid', gridTemplateColumns: '48px 1fr 150px 64px', gap: '16px', alignItems: 'center' }}>
						<div />
						<div>
							<h4>Naam ruimte</h4>
						</div>
						<div>
							<h4>Gebruiksoppervlakte</h4>
						</div>
						<div />
					</div>

					<div className="hor-separator" style={{ margin: '8px 0', borderBottom: 0 }} />

					{state.housingReducer.rentableRooms.map((r, idx) => {
						return <div>
							<div style={{ display: 'grid', gridTemplateColumns: '48px 1fr 150px 64px', gap: '16px', alignItems: 'center' }}>

								<div style={{ textAlign: 'right' }}>
									<h4 style={{ padding: 0 }}>#{idx + 1}</h4>
								</div>
								<div>
									<StyledInput
										value={r.name}
										onChange={event => {
											if (event) {
												dispatch({
													type: 'housing/rentableRoomChangeName',
													name: idx,
													value: event.target.value,
												});
											}
										}} />
								</div>
								<div>
									<CalculationTableRowTerm
										value={r.GO}
										unit='m²'
										decimals={0}
										editable={true}
										min={1}
										onValueChange={({ floatValue }, { event }) => {
											if (event) {
												dispatch({
													type: 'housing/rentableRoomChangeGO',
													name: idx,
													value: floatValue,
												});
											}
										}}
									/>
								</div>
								<div style={{ paddingTop: '4px' }}>
									<Button onClick={() => dispatch({ type: 'housing/rentableRoomDelete', name: idx })}>
										<Icon icon='fa:trash-o' />
									</Button>
								</div>
							</div>
							<div className="hor-separator" style={{ margin: '8px 0', borderBottom: 0 }} />
						</div>;
					})}
				</div>

				<div style={{ display: 'grid', gridTemplateColumns: '48px 1fr 150px 64px', gap: '16px', alignItems: 'center' }}>
					<div />
					<div>
						<h4>Totaal</h4>
					</div>
					<div>
						<CalculationTableRowTerm
							value={rentableRoomsTotalGO(state)}
							unit='m²'
							decimals={0}
							editable={false}
							min={1} />
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '16px' }}>
					<Button
						onClick={() => {
							dispatch({
								type: 'housing/rentableRoomAdd',
								value: 0,
							});
						}}
						style={{ display: 'flex', alignItems: 'center' }}>
						<Icon icon='fa:plus' style={{ fontSize: '0.9em' }} />&nbsp; Voeg ruimte toe
					</Button>
				</div>
			</div>
		</Step>
	);
}
