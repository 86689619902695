import { Icon } from '@iconify/react';
import { useContext } from 'react';
import {
	buildCost,
	terrainCost,
	buildCostsFloorFreeSector,
	managementFreeSectorCosts,
} from '../../../reducers/investingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CalculationTableRowTerm } from '../../lib/calctable/CalculationTableRowTerm';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { Step } from '../Step';
import { SERVICE_STEP, setServiceValue } from '../s6-servicecost/ServiceCostStep';
import ShowConditional from '../../lib/ShowConditional';
import { CostRowFullWidth } from '../../lib/costrow/CostRow';
import { Alert, AlertType } from '../../lib/Alert';

export const INVESTMENT_STEP = 'investment';

function setInvestingValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'investing/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

export function CostsStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const { noHouses } = state.housingReducer;

	return (
		<Step
			name='Kosten'
			id={INVESTMENT_STEP}
			// wider={true}
			description='Bereken de kosten van bouw en exploitatie'
			icon='ant-design:euro-circle-filled'
			showContent={state.stepReducer.step === INVESTMENT_STEP}
			nextStep={SERVICE_STEP}
			thisStep={INVESTMENT_STEP}
			nextStepValidations={[
				{ valid: state.investingReducer.maintenancePercentageOfRebuildValue != null && state.investingReducer.maintenancePercentageOfRebuildValue >= 0, error: 'Onderhoudskosten moeten ingevuld zijn' },
				{ valid: state.serviceReducer.inflation != null && state.serviceReducer.inflation >= 0, error: 'Indexatie van onderhoudskosten moeten ingevuld' },
				{ valid: state.investingReducer.insuranceCARPercent != null && state.investingReducer.insuranceCARPercent >= 0, error: 'Percentage opstalverzekering moet ingevuld zijn' },
				{ valid: state.investingReducer.mutationRatePercentPerYear != null && state.investingReducer.mutationRatePercentPerYear >= 0, error: 'Gemiddelde woonduur moet ingevuld zijn' },
				{ valid: state.housingReducer.noHouses.freeSector == 0 || (state.investingReducer.mutationCostFreeSector != null && state.investingReducer.mutationCostFreeSector >= 0), error: 'Kosten per mutatie gebouw woning moet ingevuld zijn' },
			]}
			summaryComponent={
				<div style={{ display: 'grid', gridTemplateColumns: '0.5fr' }}>
					<div />
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Management</span>
						<div><span className='bold-value'>
							<FormattedNumber
								value={managementFreeSectorCosts(state)}
								prefix='€ '
								className='bold-value'
								decimalScale={0} />
						</span> / jaar
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Bouwkosten</span>
						<span className='bold-value'>
							<FormattedNumber
								value={buildCost(state)}
								prefix='€ '
								className='bold-value'
								decimalScale={0} />
						</span>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Terreinkosten</span>
						<span className='bold-value'>
							<FormattedNumber
								value={terrainCost(state)}
								prefix='€ '
								className='bold-value'
								decimalScale={0} />
						</span>
					</div>
				</div>
			}>

			<div>
				<h3><Icon icon='mdi:crane' className='icon-blue' /> Bouw- en grondkosten</h3>
				<div className="col-3">

					<ShowConditional condition={noHouses.freeSector > 0}>
						<h4>Gebouw</h4>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostFreeSectorGround}
								unit='grond/m² perceel'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostFreeSectorGround', dispatch)}
							/>
						</div>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostFreeSectorFloor}
								unit='bouw/m² bvo'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostFreeSectorFloor', dispatch)}
							/>
						</div>
					</ShowConditional>
					<div>
						<h4>Herbouwwaarde</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.rebuildCost != null ? state.investingReducer.rebuildCost : buildCostsFloorFreeSector(state)}
							decimals={2}
							editable={true}
							wide={true}
							prefix='€ '
							onValueChange={setInvestingValue('rebuildCost', dispatch)}
						/>
					</div>

				</div>
				<div>
					<p className="remark">Deze bedragen zijn automatisch geschat aan de hand van de gemeente waar het project gerealiseerd wordt.</p>
				</div>
			</div>

			<div className="hor-separator" />

			{state.investingReducer.buildCostFreeSectorGround != null && state.investingReducer.buildCostFreeSectorGround != 0
				&& state.investingReducer.groundLeaseAmount != null && state.investingReducer.groundLeaseAmount != 0
				&& <CostRowFullWidth>
					<Alert
						type={AlertType.Warning}
						text="Je hebt zowel grondkosten als erfpacht ingevuld, het is niet erg gebruikelijk om zowel land te kopen als te pachten. Controleer of dit klopt." />
				</CostRowFullWidth>}

			<h3><Icon icon='mdi:location' className='icon-blue' /> Erfpacht</h3>
			<div className="col-3">
				<div>
					<h4>Grondwaarde</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.groundLeaseAmount}
						onValueChange={setInvestingValue('groundLeaseAmount', dispatch)}
						unit="totaal"
						editable={true}
						wide={true}
						decimals={2}
						prefix="€ " />
				</div>
				<div>
					<h4>Canonpercentage</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.groundLeaseRate}
						onValueChange={setInvestingValue('groundLeaseRate', dispatch)}
						editable={true}
						wide={true}
						decimals={2}
						suffix=" %" />
				</div>
				<div>
					<h4>Indexatie</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.groundLeaseIndexation}
						onValueChange={setInvestingValue('groundLeaseIndexation', dispatch)}
						unit=" per jaar"
						editable={true}
						wide={true}
						decimals={2}
						suffix=" %" />
				</div>
			</div>

			<p className="remark">Laat deze velden leeg, of zet canonpercentage op 0% als er geen erfpacht van toepassing is.</p>

			<div className="hor-separator" />

			<div>
				<h3><Icon icon='heroicons-outline:receipt-tax' className='icon-blue' /> Belasting</h3>
				<div className='col-3'>
					<div>
						<h4>OZB gebouweigenaar</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.taxOZBPropertyOwners}
							unit='% van WOZ'
							editable={true}
							wide={true}
							decimals={4}
							prefix=''
							onValueChange={setInvestingValue('taxOZBPropertyOwners', dispatch)}
						/>
					</div>
					<div>
						<h4>Rioolheffing</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.taxSewageChargesPerHousePerYear}
							unit='per jaar'
							editable={true}
							wide={true}
							decimals={2}
							prefix='€ '
							onValueChange={setInvestingValue('taxSewageChargesPerHousePerYear', dispatch)}
						/>
					</div>
					<div>
						<h4>Watersysteemheffing</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.taxWaterSystemLevyPercentOfWOZ}
							unit='% van WOZ'
							editable={true}
							wide={true}
							decimals={4}
							prefix=''
							onValueChange={setInvestingValue('taxWaterSystemLevyPercentOfWOZ', dispatch)}
						/>
					</div>
				</div>
				<p className='remark'>
					Deze tarieven verschillen per gemeente, en zijn automatisch bepaald aan de hand van de gemeente waarin het project gerealiseerd wordt.
				</p>
			</div>

			<div className="hor-separator" />

			<div>
				<h3><Icon icon='fluent:wrench-16-filled' className='icon-blue' /> Onderhoud</h3>
				<div className="col-3">
					<CalculationTableRowTerm
						value={state.investingReducer.maintenancePercentageOfRebuildValue}
						suffix='%'
						unit='van herbouwwaarde'
						decimals={2}
						editable={true}
						wide={true}
						onValueChange={setInvestingValue('maintenancePercentageOfRebuildValue', dispatch)}
					/>
					<CalculationTableRowTerm
						value={state.serviceReducer.inflation}
						unit='% stijging per jaar'
						decimals={2}
						editable={true}
						wide={true}
						onValueChange={setServiceValue('inflation', dispatch)}
					/>
				</div>
				<p className='remark'>
					Het is aan te raden een Meerjarig Onderhoudsplan (MJOP) op te stellen waarin deze kosten beter in kaart worden gebracht
				</p>
			</div>

			<div className="hor-separator" />

			<div>
				<h3><Icon icon='fa-solid:handshake' className="icon-blue" /> Verzekering</h3>
				<div>
					<h4>Opstalverzekering</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.insuranceCARPercent}
						suffix='%'
						unit='van herbouwwaarde'
						editable={true}
						wide={true}
						decimals={2}
						onValueChange={setInvestingValue('insuranceCARPercent', dispatch)}
					/>
				</div>
				<p className='mt-4 remark'>
					De opstalverzekeringspremie wordt meestal berekend als percentage van de herbouwwaarde van het gebouw.
				</p>
			</div>

			<div className="hor-separator" />

			<div>
				<h3><Icon icon='fa-solid:briefcase' className="icon-blue" /> Management</h3>
				<div className='col-2'>
					<div>
						<h4>Administratiekosten</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.administrationCostsPerM2BvoPerYear}
							unit='per m² bvo per jaar'
							editable={true}
							wide={true}
							decimals={2}
							prefix='€ '
							onValueChange={setInvestingValue('administrationCostsPerM2BvoPerYear', dispatch)}
						/>
					</div>
					<div>
						<h4>Promotiekosten</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.promotionCostsPerM2BvoPerYear}
							unit='per m² bvo per jaar'
							editable={true}
							wide={true}
							decimals={2}
							prefix='€ '
							onValueChange={setInvestingValue('promotionCostsPerM2BvoPerYear', dispatch)}
						/>
					</div>
				</div>
			</div>
		</Step>
	);
}
