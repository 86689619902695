export default function Tooltip2({ text, children }) {
	return <div className={"tooltip-wrapper" + (text == null || text.length == 0 ? ' tooltip-disabled' : '')}>
		<div className="tooltip-content">
			{children}
		</div>
		<div className="tooltip-v2">
			<div className="tooltip-v2-inner">
				{text}
			</div>
		</div>
	</div>;
}