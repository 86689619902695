import Modal from '../lib/Modal';
import FaqCollapsableArea from './FaqCollapsableArea';

export default function FaqModal({ open, onRequestClose }) {
	return <Modal isOpen={open} onRequestClose={onRequestClose} title="Frequently Asked Questions" maxWidth="700px">
		<br />
		<FaqCollapsableArea label="Wat zijn de voordelen van de haalbaarheidsscan?">
			<p>
				<ul>
					<li>Een snelle, eerste indicatie*) van de haalbaarheid van uw ontmoetingsruimte.</li>
					<li>Mogelijkheid om varianten te bepalen van de functies van de te verhuren ruimten: de maatschappelijke en commerciële voorzieningen met aanbod voor de buurtbewoners.</li>
					<li>Handvatten voor uw gesprek met uw initiatiefgroep, gemeente, andere partijen, banken en geldverstrekkers over grond, financiering en verhuur.</li>
					<li>De uitkomsten van de ingevulde haalbaarheidsscan komen beschikbaar in een pdf bestand.</li>
				</ul>
			</p>
			<p>
				*) Disclaimer: let op: de scan geeft uw vroegtijdig een eerste indicatie, maar géén garantie van de financiële haalbaarheid van uw ontmoetingsruimte voor de buurt. Dat is in deze fase onmogelijk, omdat er nog veel onzeker is.
			</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Waarvan toetst u de haalbaarheid?">
			<p>De haalbaarheidsscan geeft een indicatie van de haalbaarheid op basis van de modelbusinesscase <a href="https://www.platform31.nl/publicaties/woonvarianten-voor-senioren">Ruimte voor de buurt</a>.
				U vult gegevens in voor de mix aan ontmoetingsruimten in uw project, die kunnen verschillen in oppervlak en type gebruik (maatschappelijk of commercieel).</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Voor wie is de haalbaarheidsscan?">
			<p>De scan is vrij beschikbaar en bruikbaar voor alle partijen die betrokken zijn bij de totstandkoming van een ontmoetingsruimte met een functie voor de buurt: collectieve particuliere initiatieven in dorp en buurt, wooncoöperatieven, sociaal ondernemers, architecten, adviseurs en procesbegeleiders, conceptontwikkelaars, corporaties, ontwikkelaars en gemeenten.</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Voor welke fase is de scan geschikt?">
			<p>De scan is bedoeld voor de initiatieffase van een planontwikkeling, voor het verkennen van de haalbaarheid van de ideeën die er zijn. </p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Gezamenlijk of alleen invullen?">
			<p>Vul stap voor stap de gegevens in over het initiatief, zoals de projectinformatie, de investering, inkomsten en financiering. Dit resulteert in een samenvattend overzicht van de gegevens van het project.</p>
			<br />
			<p>Het loont om deze haalbaarheidsscan in te vullen met de leden van uw projectgroep. Het gesprek hierover maakt duidelijk: wat weten we al, bedoelen we hetzelfde en hebben we hetzelfde beeld voor ogen? De gevraagde gegevens zetten het projectteam ook alvast tot nadenken over onderdelen, die pas later in de tijd worden bepaald.</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Hoe veel tijd kost het invullen?">
			<p>Het invullen van de haalbaarheidsscan kost ongeveer een half uur tot een uur.</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Welke gegevens moet ik paraat hebben?">
			<p style={{ paddingBottom: '4px' }}>Projectinformatie:</p>
			<ul>
				<li>Het soort en aantal te verhuren ruimten</li>
				<li>Oppervlakte van de ruimten (bruto vloeroppervlak en gebruiksoppervlak).</li>
				<li>Oppervlakte van de algemene ruimten (verkeersruimten, entree, gangen, sanitair, etc.).</li>
				<li>Oppervlakte van de tuin</li>
				<li>Aantal parkeerplekken</li>
				<li>Exploitatietermijn</li>
			</ul>
			Een deel van de gegevens is automatisch ingevuld op basis van normkosten die bekend zijn vanuit landelijke kengetallen of gelden voor uw gemeente. U kunt deze gegevens wijzigen.
		</FaqCollapsableArea>
	</Modal>;
}