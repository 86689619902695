import { forwardRef } from 'react';

export const StyledInput = forwardRef((
	{ label, name, prefix, suffix, disabled, ...props },
	reference
) => {
	return (
		<div className='form-group'>
			<label htmlFor={name}>{label}</label>
			<div className='input-group'>
				{prefix && (
					<div className='prefix'>
						<span className='text-gray-500 sm:text-sm'>{prefix}</span>
					</div>
				)}
				<input
					className={`form-control fc-text-left ${prefix ? 'pl-7' : ''} ${suffix ? 'pr-12' : ''} ${disabled ? 'bg-gray-100' : ''}`}
					ref={reference}
					type='text'
					name={name}
					id={name}
					disabled={disabled}
					{...props}
				/>
				{suffix && (
					<div className='suffix'>
						<span>{suffix}</span>
					</div>
				)}
			</div>
		</div>
	);
});
