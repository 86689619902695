import { Icon } from '@iconify/react';
import { useContext, useState } from 'react';
import { classNames } from '../../../lib/classNames';

import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CalculationInput } from '../../lib/calctable/CalculationInput';
import von from '../../lib/custom-icons/Building-pana 1.svg';
import logo from '../../lib/custom-icons/Building-rafiki.svg';
import construction from '../../lib/custom-icons/Construction-rafiki.svg';
import inflation from '../../lib/custom-icons/Investing-rafiki.svg';
import manageMoney from '../../lib/custom-icons/Manage money-pana.svg';
import { Step } from '../Step';
import { RESULT_STEP } from '../s10-results/ResultSection';

export const VALUE_STEP = 'value';

function setValueValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'value/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

function ValueBlockExperiment({ title, subtitle, children, icon }) {

	return <div className={classNames('value-block')}>
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<img src={icon} alt={title} style={{ width: '60%', height: 'auto' }} />
			<h2>{title}</h2>
			<h3>{subtitle}</h3>
		</div>
		<div>{children()}</div>
	</div>;
}

function ValueBlockInput({ children, unit }) {
	return (
		<div style={{ display: 'flex', alignItems: 'baseline' }}>
			<div>{children}</div>
			<div className="unit">{unit}</div>
		</div>
	);
}

function ValueBlockDescription({ title, children }) {
	return (
		<div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr', gap: 'var(--u-16)', alignItems: 'baseline' }}>
			<div />
			<div>{children}</div>
			<div />
		</div>
	);
}

export function ValuationStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	return (
		<>
			<Step
				name='Waarde'
				id={VALUE_STEP}
				thisStep={VALUE_STEP}
				wider={true}
				description='Waarde van het gebouw automatisch berekend op basis van de gemeente of landelijk gemiddelde'
				icon='bi:cash-coin'
				showContent={state.stepReducer.step === VALUE_STEP}
				nextStep={RESULT_STEP}
				readyForNextStep={true}
				summaryComponent={
					<span>
						Waarden van het gebouw uit{' '}
						<span className='bold-value'>{state.housingReducer.municipality?.name}</span>
					</span>
				}>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--u-32)' }}>
					<ValueBlockExperiment
						icon={logo}
						title='WOZ-Waarde'
						subtitle={"Waardering volgens wet WOZ in " + state.housingReducer.municipality?.name}>
						{() => (
							<>
								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.WOZFreeSectorPerM2}
											prefix='€ '
											unit='per m²'
											onValueChange={setValueValue('WOZFreeSectorPerM2', dispatch)}
											editable={true}
											decimals={2}
											textLeft={true}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
					<ValueBlockExperiment
						icon={von}
						title='VON-Waarde'
						subtitle={'Vrij-Op-Naam-waarde in ' + state.housingReducer.municipality?.name}>
						{() => (
							<>
								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.VONFreeSectorPerM2}
											prefix='€ '
											decimals={2}
											editable={true}
											textLeft={true}
											onValueChange={setValueValue('VONFreeSectorPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
					<ValueBlockExperiment icon={manageMoney} title='Huurwaarde' subtitle='Kapitalisatiefactor'>
						{() => (
							<>
								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.VONBuyPerM2}
											prefix='€ '
											decimals={2}
											editable={true}
											textLeft={true}
											onValueChange={setValueValue('VONBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
									<ValueBlockInput unit='jaar kapitalisatiefactor'>
										<CalculationInput
											value={15}
											decimals={0}
											editable={true}
											textLeft={true}
											onValueChange={setValueValue('VONBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
					<ValueBlockExperiment
						icon={inflation}
						title='Waardestijging'
						subtitle='Verwachte jaarlijkse waardestijging'>
						{() => (
							<>
								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription>
									<ValueBlockInput unit='per jaar'>
										<CalculationInput
											value={state.valueReducer.inflationFreeSectorPerYear}
											onValueChange={setValueValue('inflationFreeSectorPerYear', dispatch)}
											suffix='%'
											editable={true}
											decimals={1}
											textLeft={true}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
				</div>
			</Step>
		</>
	);
}
