import { useContext } from 'react';

import { totalNoOfHouses } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { Alert, AlertType } from '../../lib/Alert';
import { CostColumn } from '../../lib/costrow/CostColumn';
import { CostInput } from '../../lib/costrow/CostInput';
import { CostRow, CostRowFullWidth } from '../../lib/costrow/CostRow';
import { Step } from '../Step';
import MunicipalitySelector from './MunicipalitySelector';
import { UNIT_STEP } from '../s2-units/UnitLayoutStep';

function setIncomeValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'income/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

export const PROJECT_STEP = 'project';

export function ProjectStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	return (
		<Step
			name='Ontmoetingsruimte'
			id={PROJECT_STEP}
			description='Wat, waar, en wanneer: hoe komt de ontmoetingsruimte er uit te zien?'
			icon='clarity:building-solid'
			showContent={state.stepReducer.step === PROJECT_STEP}
			nextStepValidations={[
				{
					valid: state.housingReducer.municipality != null,
					error: 'Gemeente moet geselecteerd zijn',
				},
				{
					valid: state.incomeReducer.explotationFromYear != null,
					error: 'Eerste exploitatiejaar moet ingevuld zijn',
				},
				{
					valid: state.incomeReducer.explotationTermPeriodYears != null && state.incomeReducer.explotationTermPeriodYears >= 3,
					error: 'Exploitatieduur moet ingevuld en minstens 3 jaar zijn',
				},
			]}
			thisStep={PROJECT_STEP}
			nextStep={UNIT_STEP}
			summaryComponent={
				<div>
					<div>
						<span className="bold-value"/><span> De geselecteerde gemeente is </span><span className='bold-value'>{state.housingReducer.municipality?.name}</span>
						<br/>
						<span className="bold-value"/><span> Het startjaar is in </span><span className='bold-value'>{state.incomeReducer.explotationFromYear}</span><span> en het eindigt over <span className='bold-value'>{state.incomeReducer.explotationTermPeriodYears} jaar</span></span>
					</div>
				</div>
			}
			summaryInError={totalNoOfHouses(state) == null || isNaN(totalNoOfHouses(state))}>

			<div className='col'>
				<div>
					<h3>Gemeente</h3>

					<div style={{ maxWidth: '300px' }}>
						<MunicipalitySelector
							onChange={(mId, municipality) => dispatch({	type: 'housing/setMunicipality', municipality })}
							value={state.housingReducer.municipality?.id} />
					</div>

					<p className="remark mt-4">Staat uw gemeente er niet tussen? Kies dan een vergelijkbare gemeente of 'Nederlands Gemiddelde'. De gemeente-afhankelijke gegevens (gemiddelde WOZ-waarden, OZB-tarief, rioolheffing etc.) zijn later zelf aan te passen.</p>
				</div>
			</div>

			<div className="hor-separator" />

			<CostRow name='Exploitatie' cols={2}>
				{state.incomeReducer.explotationTermPeriodYears > 30 && <CostRowFullWidth>
					<Alert
						type={AlertType.Warning}
						text="De termijn verhogen maakt een project moeilijker te financiëren. Probeer eerst andere maatregelen te nemen, zoals het verhogen van de huur, of verlagen van kosten." />
				</CostRowFullWidth>}

				<CostColumn icon='mdi:calendar-blank' name='Aanvang'>
					<CostInput
						value={state.incomeReducer.explotationFromYear}
						decimals={0}
						thousandSeparator=''
						onValueChange={setIncomeValue('explotationFromYear', dispatch)}
					/>
				</CostColumn>

				<CostColumn icon='mdi:calendar-clock' name='Termijn'>
					<CostInput
						value={state.incomeReducer.explotationTermPeriodYears}
						unit='jaar'
						decimals={0}
						strictMax={100}
						helpText='Gebruikelijk is 20 of 30 jaar'
						onValueChange={({ floatValue }, { event }) => {
							if (event) {
								let modifyValue = floatValue;
								if (modifyValue == null) modifyValue = 1;
								dispatch({
									type: 'income/setExplotationTermPeriodYears',
									value: modifyValue,
								});
							}
						}} />
				</CostColumn>
			</CostRow>
		</Step>
	);
}
