import { createSelector } from 'reselect';

import { building, buildingAreaTotalVvo } from './buildingReducer';
import { noHouses, rentableRoomsTotalGO } from './housingReducer';
import { terrain, totalGardenArea } from './terrainReducer';

export function serviceReducer(state, action) {
	switch (action.type) {
	case 'service/setValue': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				[action.name]: action.value,
			},
		};
	}
	case 'service/toggleElectricityEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				electricityEnabled: !state.serviceReducer.electricityEnabled,
			},
		};
	}
	case 'service/toggleHeatingEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				heatingEnabled: !state.serviceReducer.heatingEnabled,
			},
		};
	}
	case 'service/togglePersonnelEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				personnelEnabled: !state.serviceReducer.personnelEnabled,
			},
		};
	}
	case 'service/toggleMaintenanceEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				maintenanceEnabled: !state.serviceReducer.maintenanceEnabled,
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const initialServiceReducer = {
	serviceReducer: {
		electricityEnabled: false,
		electricityKWhHousePerYear: 0,
		electricityHousePricePerKWh: 0,

		electricityKWhPerM2: 10,
		electricityPricePerKWh: 0.22,

		heatingEnabled: false,
		heatingGJPerHousePerYear: 0,
		heatingHousePricePerGJ: 0,

		heatingGJPerM2: 0.25,
		heatingPricePerGJ: 25,

		personnelEnabled: false,
		staffHouseMasterHoursPerWeek: 8,
		staffHouseMasterPricePerHour: 30,

		staffAdministratorHoursPerWeek: 1,
		staffAdministratorPricePerHour: 35,

		staffCleaningPerWeekOverwritten: undefined,
		staffCleaningPricePerHour: 23,

		maintenanceEnabled: false,
		maintenanceRedemptionOfTenantPerM2PerYear: 0,
		maintenanceGardenPerM2PerPlot: 0,

		inflation: 2,
	},
};

const service = (state) => state.serviceReducer;

export const houseMasterTotalYearCosts = createSelector(service, (service) => (service.staffHouseMasterHoursPerWeek * service.staffHouseMasterPricePerHour * 52)
);

// It is assumed that a person can clean 450 sqm per hour, so this computes how many hours are needed per week to clean the space
export const staffCleaningPerWeek = createSelector(service, building, rentableRoomsTotalGO, (service, building, rentableRoomsTotalGO) => service.staffCleaningPerWeekOverwritten ?? ((building.commonAreaVvo + rentableRoomsTotalGO) / 450));

export const administratorTotalYearCosts = createSelector(service, (service) => (service.staffAdministratorHoursPerWeek * service.staffAdministratorPricePerHour * 52)
);

export const cleaningAllSurfaceAreaTotalYearCosts = createSelector(service, staffCleaningPerWeek, (service, staffCleaningPerWeek) => (staffCleaningPerWeek * service.staffCleaningPricePerHour * 52)
);

export const totalPersonnelYearCosts = createSelector(
	houseMasterTotalYearCosts,
	administratorTotalYearCosts,
	cleaningAllSurfaceAreaTotalYearCosts,
	(houseMasterTotalYearCosts, administratorTotalYearCosts, cleaningAllSurfaceAreaTotalYearCosts) => houseMasterTotalYearCosts + administratorTotalYearCosts + cleaningAllSurfaceAreaTotalYearCosts
);

export const maintenanceTotalYearCosts = createSelector(
	service,
	totalGardenArea,
	(service, totalGardenArea) => service.maintenanceGardenPerM2PerPlot * totalGardenArea
);
export const houseMasterTotalYearCostsSocialHousing = createSelector(
	service,
	noHouses,
	houseMasterTotalYearCosts,
	(service, noHouses, houseMasterTotalYearCosts) => {
		return (noHouses.social / (noHouses.social + noHouses.freeSector)) * houseMasterTotalYearCosts;
	}
);


export const electricityTotalYearCosts = createSelector(
	service,
	buildingAreaTotalVvo,
	(service, buildingAreaTotalVvo) => service.electricityKWhPerM2 * service.electricityPricePerKWh * buildingAreaTotalVvo
);

export const heatingTotalYearCosts = createSelector(
	service,
	buildingAreaTotalVvo,
	(service, buildingAreaTotalVvo) => service.heatingGJPerM2 * service.heatingPricePerGJ * buildingAreaTotalVvo
);

export const electricityTotalYearCostsSocial = createSelector(service, noHouses, (service, noHouses) => (service.electricityKWhHousePerYear * service.electricityHousePricePerKWh * noHouses.social)
);

export const heatingTotalYearCostsSocial = createSelector(service, noHouses, (service, noHouses) => (service.heatingGJPerHousePerYear * service.heatingHousePricePerGJ * noHouses.social)
);

export const serviceCostsSocialHousingWithoutHouseMaster = createSelector(
	electricityTotalYearCostsSocial,
	heatingTotalYearCostsSocial,
	(electricityTotalYearCostsSocial, heatingTotalYearCostsSocial) => {
		// TODO vraag Jasper zie Gitlab issue
		// const staffAdministrator =
		// 	(noHouses.social / (noHouses.social + noHouses.freeSector)) * administratorTotalYearCosts;
		// const staffCleaning = (noHouses.social / (noHouses.social + noHouses.freeSector)) * cleaningTotalYearCosts;
		// const staffCosts = staffHouseMaster + staffAdministrator + staffCleaning;

		return electricityTotalYearCostsSocial + heatingTotalYearCostsSocial;
	}
);

export const serviceCostsSocialHousing = createSelector(
	houseMasterTotalYearCostsSocialHousing,
	serviceCostsSocialHousingWithoutHouseMaster,
	(houseMasterTotalYearCostsSocialHousing, serviceCostsSocialHousingWithoutHouseMaster) => {
		return houseMasterTotalYearCostsSocialHousing + serviceCostsSocialHousingWithoutHouseMaster;
	}
);

export const houseMasterTotalYearCostsFreeSector = createSelector(
	service,
	noHouses,
	houseMasterTotalYearCosts,
	(service, noHouses, houseMasterTotalYearCosts) => {
		return (noHouses.freeSector / (noHouses.social + noHouses.freeSector)) * houseMasterTotalYearCosts;
	}
);

export const electricityTotalYearCostsFreeSector = createSelector(service, noHouses, (service, noHouses) => (service.electricityKWhHousePerYear * service.electricityHousePricePerKWh * noHouses.freeSector)
);

export const heatingTotalYearCostsFreeSector = createSelector(service, noHouses, (service, noHouses) => (service.heatingGJPerHousePerYear * service.heatingHousePricePerGJ * noHouses.freeSector)
);

export const serviceCostsFreeSectorWithoutHouseMaster = createSelector(
	electricityTotalYearCosts,
	heatingTotalYearCosts,
	(electricityTotalYearCosts, heatingTotalYearCosts) => {
		return electricityTotalYearCosts + heatingTotalYearCosts;
	}
);

export const serviceCostsFreeSector = createSelector(
	serviceCostsFreeSectorWithoutHouseMaster,
	houseMasterTotalYearCostsFreeSector,
	(serviceCostsFreeSectorWithoutHouseMaster, houseMasterTotalYearCostsFreeSector) => {
		// TODO vraag Jasper zie Gitlab issue
		// const staffAdministrator =
		// 	(noHouses.freeSector / (noHouses.social + noHouses.freeSector)) * administratorTotalYearCosts;
		// const staffCleaning = (noHouses.freeSector / (noHouses.social + noHouses.freeSector)) * cleaningTotalYearCosts;
		// const staffCosts = staffHouseMaster + staffAdministrator + staffCleaning;

		return serviceCostsFreeSectorWithoutHouseMaster + houseMasterTotalYearCostsFreeSector;
	}
);



// total service costs
export const totalServiceCosts = createSelector(
	electricityTotalYearCosts,
	heatingTotalYearCosts,
	totalPersonnelYearCosts,
	(electricityTotalYearCosts, heatingTotalYearCosts, totalPersonnelYearCosts) => {
		return electricityTotalYearCosts + heatingTotalYearCosts + totalPersonnelYearCosts;
	});

export const electricityTotalYearCostsCommonArea = createSelector(service, building, (service, building) => (service.electricityKWhCommonAreaPerM2 * service.electricityCommonAreaPricePerKWh * building.commonAreaVvo)
);

export const heatingTotalYearCostsCommonArea = createSelector(
	service,
	building,
	(service, building) => (service.heatingGJPerM2 * service.heatingPricePerGJ * building.commonAreaBvo) // why bvo?
);

export const serviceCostsCommonAreaWithoutHouseMaster = createSelector(
	electricityTotalYearCostsCommonArea,
	heatingTotalYearCostsCommonArea,
	cleaningAllSurfaceAreaTotalYearCosts,
	(electricityTotalYearCostsCommonArea, heatingTotalYearCostsCommonArea, cleaningAllSurfaceAreaTotalYearCosts) => {
		return electricityTotalYearCostsCommonArea + heatingTotalYearCostsCommonArea + cleaningAllSurfaceAreaTotalYearCosts;
	}
);

export const serviceCostsCommonArea = createSelector(
	houseMasterTotalYearCosts,
	houseMasterTotalYearCostsSocialHousing,
	houseMasterTotalYearCostsFreeSector,
	serviceCostsCommonAreaWithoutHouseMaster,
	(
		houseMasterTotalYearCosts,
		houseMasterTotalYearCostsSocialHousing,
		houseMasterTotalYearCostsFreeSector,
		serviceCostsCommonAreaWithoutHouseMaster
	) => {
		const staffHouseMaster =
			houseMasterTotalYearCosts - houseMasterTotalYearCostsSocialHousing - houseMasterTotalYearCostsFreeSector;
		return serviceCostsCommonAreaWithoutHouseMaster + staffHouseMaster;
	}
);

export const totalServiceCostsReal = createSelector(
	serviceCostsFreeSector,
	administratorTotalYearCosts,
	maintenanceTotalYearCosts,
	cleaningAllSurfaceAreaTotalYearCosts,
	(serviceCostsFreeSector, administratorTotalYearCosts, maintenanceTotalYearCosts, cleaningAllSurfaceAreaTotalYearCosts) => serviceCostsFreeSector + administratorTotalYearCosts + maintenanceTotalYearCosts + cleaningAllSurfaceAreaTotalYearCosts
);

export const totalElectricity = createSelector(
	electricityTotalYearCostsSocial,
	electricityTotalYearCostsFreeSector,
	electricityTotalYearCostsCommonArea,
	(electricityTotalYearCostsSocial, electricityTotalYearCostsFreeSector, electicityTotalYearCostsCommonArea) => {
		return (
			electricityTotalYearCostsSocial + electricityTotalYearCostsFreeSector + electicityTotalYearCostsCommonArea
		);
	}
);

export const totalHeating = createSelector(
	heatingTotalYearCostsSocial,
	heatingTotalYearCostsFreeSector,
	heatingTotalYearCostsCommonArea,
	(heatingTotalYearCostsSocial, heatingTotalYearCostsFreeSector, heatingTotalYearCostsCommonArea) => {
		return heatingTotalYearCostsSocial + heatingTotalYearCostsFreeSector + heatingTotalYearCostsCommonArea;
	}
);

export const totalServiceCostsHuurcommissie = createSelector(
	electricityTotalYearCosts,
	heatingTotalYearCosts,
	houseMasterTotalYearCosts,
	cleaningAllSurfaceAreaTotalYearCosts,
	maintenanceTotalYearCosts,
	(
		electricityTotalYearCosts,
		heatingTotalYearCosts,
		houseMasterTotalYearCosts,
		cleaningTotalYearCosts,
		maintenanceTotalYearCosts,
	) => {
		const tussenstand =
			electricityTotalYearCosts +
			heatingTotalYearCosts +
			houseMasterTotalYearCosts * 0.7 +
			cleaningTotalYearCosts +
			maintenanceTotalYearCosts;

		const adminCostsVolgensHuurcommissie = tussenstand * 0.05;
		const derving = (tussenstand + adminCostsVolgensHuurcommissie) * 0.02;
		const andereMagie = (tussenstand + adminCostsVolgensHuurcommissie + derving) * 0.02;
		return tussenstand + adminCostsVolgensHuurcommissie + derving - andereMagie;
	}
);

export const totalServiceCostsVsHuurcommissieRatio = createSelector(
	totalServiceCostsHuurcommissie,
	totalServiceCostsReal,
	(totalServiceCostsHuurcommissie, totalServiceCostsReal) => {
		const result = totalServiceCostsHuurcommissie / totalServiceCostsReal;
		return Number.isNaN(result) ? 0 : result;
	}
);
