import { useMemo, useContext } from 'react';

import { buildingAreaTotalBvo, buildingFootprint, floors } from '../../../reducers/buildingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { totalGardenArea, totalParkingArea, totalTerrainArea } from '../../../reducers/terrainReducer';
import { CalculationTableRow } from '../../lib/calctable/CalculationTableRow';
import { CalculationTableRowTerm, Sign } from '../../lib/calctable/CalculationTableRowTerm';
import HorizontalColumnChart from '../../lib/HorizontalColumnChart';
import { hasValidValues, Step } from '../Step';
import { INVESTMENT_STEP } from '../s5-costs/CostsStep';
import { totalHousesAreaBvo } from '../../../reducers/housingReducer';

export const TERRAIN_STEP = 'terrain';

export function TerrainStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const housing = totalHousesAreaBvo(state);

	return (
		<Step
			icon='mdi:terrain'
			id={TERRAIN_STEP}
			name='Terrein'
			description='Geef aanvullende informatie over tuin en parkeerplaatsen, die aanwezig zijn rondom de ontmoetingsruimte'
			showContent={state.stepReducer.step === TERRAIN_STEP}
			nextStep={INVESTMENT_STEP}
			thisStep={TERRAIN_STEP}
			nextStepValidations={[
				{ valid: state.terrainReducer.gardens >= 0, error: 'Er moet een aantal tuinen ingevuld zijn, vul 0 in als er geen tuinen zijn' },
				{ valid: state.terrainReducer.parkingSpots >= 0, error: 'Er moet een aantal parkeerplekken ingevuld zijn, vul 0 in als er geen parkeerplekken nodig zijn' },
			]}
			summaryInError={hasValidValues([ totalTerrainArea(state) ])}
			summaryComponent={
				<div>
					<div>
						<span className='bold-value'> {buildingFootprint(state).toFixed(0)}</span><span> m² footprint</span><br />
						<span className='bold-value'> {totalTerrainArea(state).toFixed(0)}</span><span> m² bruto totaal kaveloppervlak</span>
					</div>
				</div>
			}>
			<CalculationTableRow title='Footprint'>
				<CalculationTableRowTerm
					title='Woningoppervlakte'
					value={buildingAreaTotalBvo(state)}
					unit='m² bvo' />
				<CalculationTableRowTerm
					sign='/'
					value={floors(state)}
					unit='verdiepingen'
					editable={true}
					mustBeInteger={false}
					decimals={1}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'building/setFloors',
								value: floatValue,
							});
						}
					}} />
				{/* eslint-disable-next-line */}
				<CalculationTableRowTerm
					sign="="
					value={buildingFootprint(state)}
					unit='m² footprint'
					editable={true}
					id='footprint'
					initialValue={useMemo(() => buildingAreaTotalBvo(state) / floors(state), [ housing ])}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'building/setBuildingFootprint',
								value: parseInt(floatValue, 10),
							});
						}
					}} />
			</CalculationTableRow>

			<br />


			<CalculationTableRow title='Tuinoppervlakte' helpText="Neem hierin alle groenoppervalk buiten het gebouw mee">
				<CalculationTableRowTerm
					sign=''
					value={state.terrainReducer.gardenArea}
					unit='m²'
					editable={true}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setGardenArea',
								value: floatValue,
							});
						}
					}}
				/>
				<div />
				<CalculationTableRowTerm
					sign='='
					editable={false}
					value={state.terrainReducer.gardenArea}
					unit='m² tuin'
					id='totalGardenArea'
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setGardenArea',
								value: floatValue,
							});
						}
					}}
				/>
			</CalculationTableRow>

			<br />
			{/* <div className="hor-separator" />*/}

			<CalculationTableRow
				title='Parkeerplaatsen'
				helpText='Een standaard parkeerplek neemt 2,5 * 8 = 20 m² in beslag. Controleer het vereiste aantal parkeerplaatsen bij de gemeente.'>
				<CalculationTableRowTerm
					value={state.terrainReducer.parkingSpots}
					unit='parkeerplaatsen'
					editable={true}
					mustBeInteger={true}
					decimals={0}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setParkingSpots',
								value: floatValue,
							});
						}
					}}
				/>
				<CalculationTableRowTerm
					sign='*'
					value={state.terrainReducer.parkingSpotArea}
					unit='m²'
					editable={true}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setParkingSpotArea',
								value: floatValue,
							});
						}
					}}
				/>
				<CalculationTableRowTerm sign="=" value={totalParkingArea(state)} id='totalParkingArea' unit='m² perceel' />
			</CalculationTableRow>

			<div className='hor-separator' />

			<h3>Voorbeeld</h3>

			<div style={{ width: '100%', height: '150px', display: 'flex', alignItems: 'flex-end' }}>
				<div
					style={{
						display: 'flex',
						minWidth: '150px',
						flex: buildingFootprint(state),
						flexDirection: 'column-reverse',
						width: '100%',
						overflowX: 'hidden',
						overflowY: 'visible',
						paddingTop: '8px',
						marginTop: '12px',
					}}>
					{Array.apply(null, Array(Math.min(Math.ceil(floors(state)), 4) || 0)).map((_, idx, arr) => (
						<div
							key={idx}
							style={{
								flex: 1,
								minHeight: '32px',
								lineHeight: '30px',
								boxShadow:
									'0 0 1px inset rgba(0, 0, 0, 0.5)' +
									(idx >= 3 && floors(state) > 4 ? ', 0 -4px 4px #818CF8' : ''),
								background: '#818CF8',
								color: 'white',
								textAlign: 'center',
								borderBottom: idx > 0 ? 'none' : '8px solid #312D81',
							}}
							className='text-sm font-semibold'>
							{idx >= 3 && floors(state) > 4 && <span>3e - {floors(state) - 1}e</span>}
							{idx >= 3 && floors(state) <= 4 && <span>3e verdieping</span>}
							{idx < 3 && <span>{idx === 0 ? 'Begane grond' : idx + 'e verdieping'}</span>}
						</div>
					))}
				</div>
				{state.terrainReducer.gardenArea > 0 && <div style={{ flex: (state.terrainReducer.gardenArea || 0) }}>
					<div style={{ borderBottom: '8px solid #22C55E', textAlign: 'center', lineHeight: '30px', minHeight: '32px', background: 'var(--col-green-100)', overflow: 'hidden', color: 'var(--col-green-800)' }}>
						<div style={{ width: '1px', display: 'inline-flex', justifyContent: 'center' }}>
							Tuin
						</div>
					</div>
				</div>}
				{totalParkingArea(state) > 0 && <div style={{ flex: (totalParkingArea(state) || 0) }}>
					<div style={{ borderBottom: '8px solid #616169', textAlign: 'center', padding: '0 4px', lineHeight: '30px', minHeight: '32px', background: 'var(--col-grey-200)', overflow: 'hidden' }}>
						<div style={{ width: '1px', display: 'inline-flex', justifyContent: 'center' }}>
							Parkeren
						</div>
					</div>
				</div>}
			</div>

			<div className="hor-separator" />

			<div className='col-2'>
				<div>
					<div style={{ maxHeight: '150px', borderRight: '1px solid #ccc' }}>
						<br />
						<HorizontalColumnChart
							maxWidth={350}
							columns={[
								{ color: '#6366F1', value: buildingFootprint(state), label: 'Gebouw' },
								{ color: '#22C55E', value: totalGardenArea(state), label: 'Tuin' },
								{ color: '#61616A', value: totalParkingArea(state), label: 'Parkeren' },
							]}
						/>
					</div>
				</div>
				<div style={{ paddingLeft: '32px' }}>
					<div>
						<CalculationTableRowTerm
							wide={true}
							value={buildingFootprint(state)}
							unit='m² bvo'
							reference={{ text: 'Footprint', id: 'footprint', step: 'building' }}
						/>
					</div>
					<div>
						<CalculationTableRowTerm
							wide={true}
							value={totalGardenArea(state)}
							unit='m² tuin'
							reference={{ text: 'Tuinoppervlakte', id: 'totalGardenArea', step: 'terrain' }}
						/>
					</div>
					<div>
						<CalculationTableRowTerm
							wide={true}
							value={totalParkingArea(state)}
							unit='m²'
							reference={{ text: 'Parkeerplaatsen', id: 'totalParkingArea', step: 'terrain' }}
						/>
					</div>
					<div className='flex align-center pt-3' style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
						<div style={{ position: 'relative', left: '-16px', top: '2px' }}>
							<Sign sign='+' />
						</div>
						<div style={{ flex: 1 }}>
							<div style={{ borderTop: '2px solid #aaa' }} />
						</div>
					</div>
					<div>
						<CalculationTableRowTerm wide={true} unit='m² kavel nodig' value={totalTerrainArea(state)} />
					</div>
				</div>
			</div>
		</Step>
	);
}
