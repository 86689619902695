import { Icon } from '@iconify/react';
import { useContext } from 'react';
import { classNames } from '../../../lib/classNames';

import {
	administratorTotalYearCosts,
	cleaningAllSurfaceAreaTotalYearCosts,
	electricityTotalYearCosts,
	heatingTotalYearCosts,
	houseMasterTotalYearCosts,
	maintenanceTotalYearCosts,
	staffCleaningPerWeek,
	totalPersonnelYearCosts,
	totalServiceCostsReal,
} from '../../../reducers/serviceReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CostInput } from '../../lib/costrow/CostInput';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { HeatingHouseIcon } from '../../lib/custom-icons/HeatingHouseIcon';
import { HousekeeperIcon } from '../../lib/custom-icons/HousekeeperIcon';
import { MaintenanceIcon } from '../../lib/custom-icons/MaintenanceIcon';
import { SirenIcon } from '../../lib/custom-icons/SirenIcon.js';
import { Step } from '../Step';
import { INCOME_STEP } from '../s7-income/IncomeStep';
import { roundNumberToOneDecimal } from '../../../lib/rounding';

export const SERVICE_STEP = 'service';

export function setServiceValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'service/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

const curlyBraceStyle = {
	borderStyle: 'solid',
	borderWidth: '0px 30px 0px 0px',
	borderImage: 'url(/Curly_bracket_right.svg) 1 100 stretch',
};

// {name, month, year}, total

function ServiceMixSummary({ items, total }) {
	return <div className="service-mix-summary">
		<div className="service-mix-summary-row service-mix-summary-header">
			<div />
			<div>Maand</div>
			<div>Jaar</div>
		</div>
		{items.map((item, idx) => <div key={idx} className="service-mix-summary-row">
			<div>{item.name}</div>
			<div>
				<FormattedNumber value={item.month} prefix='€ ' decimalScale={0} />
			</div>
			<div>
				<FormattedNumber value={item.year} prefix='€ ' decimalScale={0} />
			</div>
		</div>)}
		{total != null && <div className='service-mix-summary-row service-mix-summary-footer'>
			<div>Totaal</div>
			<div><FormattedNumber value={total.month} prefix='€ ' className='font-medium' decimalScale={0} /></div>
			<div><FormattedNumber value={total.year} prefix='€ ' className='font-medium' decimalScale={0} /></div>
		</div>}
	</div>;
}

function ServiceCostComponent({ icon, title, remark, disabledText, summary, children, isEnabled, setEnabled, mandatory = true }) {
	return <div className={classNames('service-cost', !isEnabled && 'service-cost-disabled')}>
		<div className="service-cost-header">
			<div className="service-cost-icon">{icon}</div>
			<div>
				<h3>{title}</h3>
				<span className='remark'>{remark}</span>
			</div>
		</div>

		<div className="service-cost-content">
			{!mandatory ? <div className="service-cost-content-checkbox">
				<input
					type='checkbox'
					checked={isEnabled}
					onChange={() => setEnabled()} />
			</div> : <div className="service-cost-content-checkbox" />}
			{isEnabled ? <div className="service-cost-fields">
				{children}
				{summary}
			</div> : <div className="service-cost-disabled-text">{disabledText}</div>}
		</div>
	</div>;
}

export function ServiceCostStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	return (
		<Step
			name='Servicekosten'
			id={SERVICE_STEP}
			wider={true}
			description='Welke diensten zijn er centraal geregeld, en worden via de servicekosten verrekend bij vaste contracten of zijn bij losse verhuur inbegrepen in het verhuurtarief.'
			icon='ic:twotone-cleaning-services'
			showContent={state.stepReducer.step === SERVICE_STEP}
			nextStep={INCOME_STEP}
			thisStep={SERVICE_STEP}
			readyForNextStep={true}
			summaryComponent={
				<div className="col-1">
					<div>
						<div style={{ marginBottom: 'var(--u-8)' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Operatiekosten</div>
								<div>
									<FormattedNumber
										value={(totalServiceCostsReal(state) || 0) / 12}
										prefix='€ '
										className='bold-value'
										decimalScale={0} />{' '}
									/ maand
								</div>
								<div>
									<FormattedNumber
										value={totalServiceCostsReal(state) || 0}
										prefix='€ '
										className='bold-value'
										decimalScale={0}
									/>{' '}
									/ jaar
								</div>
							</div>
						</div>
					</div>
				</div>
			}>

			<ServiceCostComponent
				icon={<div style={{ zoom: '150%' }}><Icon icon='mdi:home-lightning-bolt' /></div>}
				title='Elektriciteitsverbruik'
				remark='Specificeer hier het elektriciteitsverbruik voor de hele gebouw'
				isEnabled={true}
				setEnabled={() => dispatch({ type: 'service/toggleElectricityEnabled' })}
				disabledText='Het elektriciteitsverbruik wordt niet meegenomen, en wordt door de huurders zelf betaald'
				summary={
					<ServiceMixSummary
						items={[
							{
								name: 'Elektriciteitsverbruik',
								year: electricityTotalYearCosts(state),
								month: electricityTotalYearCosts(state) / 12,
							},
						]}
					/>
				}>

				<div style={{ ...curlyBraceStyle }} className="col-2">
					<div>
						<h4>Totale oppervlakte</h4>
						<CostInput
							value={state.serviceReducer.electricityKWhPerM2}
							unit='kWh/m²'
							onValueChange={setServiceValue('electricityKWhPerM2', dispatch)} />
					</div>
					<div>
						<h4>Prijs</h4>
						<CostInput
							value={state.serviceReducer.electricityPricePerKWh}
							unit='per kWh'
							prefix='€ '
							onValueChange={setServiceValue('electricityPricePerKWh', dispatch)} />
					</div>
				</div>
			</ServiceCostComponent>

			<div className="hor-separator" />

			<ServiceCostComponent
				icon={<HeatingHouseIcon />}
				isEnabled={true}
				setEnabled={() => dispatch({ type: 'service/toggleHeatingEnabled' })}
				remark='Specificeer hier het warmteverbruik voor verhuurbare ruimtes, en/of de algemene ruimten'
				title='Warmteverbruik'
				disabledText='Het warmteverbruik wordt niet meegenomen, en wordt door de huurders zelf betaald'
				summary={
					<ServiceMixSummary
						items={[
							{
								name: 'Warmteverbruik',
								year: heatingTotalYearCosts(state),
								month: heatingTotalYearCosts(state) / 12,
							},
						]}
					/>
				}>
				<div style={curlyBraceStyle}>
					<div className="col-2">
						<div>
							<h4>Totale oppervlakte</h4>
							<CostInput
								value={state.serviceReducer.heatingGJPerM2}
								unit='GJ/m²'
								helpText={'ongeveer ' + (state.serviceReducer.heatingGJPerM2 != null ? roundNumberToOneDecimal(state.serviceReducer.heatingGJPerM2 * 31.6) : 0) + ' m³ aardgas/m²'}
								onValueChange={setServiceValue('heatingGJPerM2', dispatch)} />
						</div>
						<div>
							<h4>Prijs</h4>
							<CostInput
								value={state.serviceReducer.heatingPricePerGJ}
								unit='per GJ'
								prefix='€ '
								helpText={'per 31.6 m³ aardgas'}
								onValueChange={setServiceValue('heatingPricePerGJ', dispatch)} />
						</div>
					</div>
				</div>
			</ServiceCostComponent>

			<div className="hor-separator" />

			<ServiceCostComponent
				remark='Specificeer hier personeelskosten, zoals beheer en schoonmaak'
				isEnabled={true}
				setEnabled={() => dispatch({ type: 'service/togglePersonnelEnabled' })}
				icon={<HousekeeperIcon />}
				title='Personeel'
				disabledText='Er is geen personeel gespecificeerd'
				summary={
					<ServiceMixSummary
						items={[
							{
								name: 'Beheerder',
								month: houseMasterTotalYearCosts(state) / 12,
								year: houseMasterTotalYearCosts(state),
							},
							{
								name: 'Administratie',
								month: administratorTotalYearCosts(state) / 12,
								year: administratorTotalYearCosts(state),
							},
							{
								name: 'Schoonmaak',
								month: cleaningAllSurfaceAreaTotalYearCosts(state) / 12,
								year: cleaningAllSurfaceAreaTotalYearCosts(state),
							},
						]}
						total={{
							year: totalPersonnelYearCosts(state),
							month: totalPersonnelYearCosts(state) / 12,
						}}
					/>
				}>
				<div style={curlyBraceStyle}>
					<div className="col-3">
						<div>
							<h4>Beheerder</h4>
							<CostInput
								value={state.serviceReducer.staffHouseMasterHoursPerWeek}
								unit='uur/week'
								onValueChange={setServiceValue('staffHouseMasterHoursPerWeek', dispatch)}
							/>
							<CostInput
								value={state.serviceReducer.staffHouseMasterPricePerHour}
								unit='per uur'
								prefix='€ '
								onValueChange={setServiceValue('staffHouseMasterPricePerHour', dispatch)}
							/>
						</div>

						<div>
							<h4>Administratie</h4>
							<CostInput
								value={state.serviceReducer.staffAdministratorHoursPerWeek}
								unit='uur/week'
								onValueChange={setServiceValue('staffAdministratorHoursPerWeek', dispatch)}
							/>
							<CostInput
								value={state.serviceReducer.staffAdministratorPricePerHour}
								unit='per uur'
								prefix='€ '
								onValueChange={setServiceValue('staffAdministratorPricePerHour', dispatch)}
							/>
						</div>

						<div>
							<h4>Schoonmaak</h4>
							<CostInput
								value={staffCleaningPerWeek(state)}
								unit='uur/week'
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'service/setValue',
											name: 'staffCleaningPerWeekOverwritten',
											value: floatValue,
										});
									}
								}}
							/>
							<CostInput
								value={state.serviceReducer.staffCleaningPricePerHour}
								unit='per uur'
								prefix='€ '
								onValueChange={setServiceValue('staffCleaningPricePerHour', dispatch)} />
						</div>
					</div>
				</div>
			</ServiceCostComponent>

			<div className="hor-separator" />

			<ServiceCostComponent
				isEnabled={true}
				icon={<MaintenanceIcon />}
				remark='Specificeer hier het tuinonderhoud'
				setEnabled={() => dispatch({ type: 'service/toggleMaintenanceEnabled' })}
				title='Tuinonderhoud'
				disabledText='Er is geen tuinonderhoud gespecificeerd'
				summary={
					<ServiceMixSummary
						items={[
							{ name: 'Tuinonderhoud', year: maintenanceTotalYearCosts(state), month: maintenanceTotalYearCosts(state) / 12	},
						]}
					/>
				}>
				<div style={curlyBraceStyle}>
					<div className="col-1">
						<div>
							<h4>Tuinonderhoud</h4>

							<CostInput
								value={state.serviceReducer.maintenanceGardenPerM2PerPlot}
								unit='per m² perceel'
								onValueChange={setServiceValue('maintenanceGardenPerM2PerPlot', dispatch)} />
						</div>
					</div>
				</div>
			</ServiceCostComponent>
		</Step>
	);
}
