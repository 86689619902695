import { createSelector } from 'reselect';

import { housing } from './housingReducer';
import { totalBuyCostsSocialAndFreeSectorOnly } from './investingReducer';
import { value } from './valueReducer';
import { incomeReducer } from './incomeReducer';

export function financingReducer(state, action) {
	switch (action.type) {
	case 'financing/setValue': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				[action.name]: action.value,
			},
		};
	}
	case 'financing/setBuildingLoanValue': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				buildingLoan: {
					...state.financingReducer.buildingLoan,
					[action.name]: action.value,
				},
			},
		};
	}
	case 'financing/setMortgageValue': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				mortgage: {
					...state.financingReducer.mortgage,
					[action.name]: action.value,
				},
			},
		};
	}
	case 'financing/addSoftLoan': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: state.financingReducer.softLoans.concat(
					{
						name: 'Nieuwe lening',
						type: FinancingType.linear,
						amount: 0,
						redemptionPercentage: 100,
						years: incomeReducer.explotationTermPeriodYears,
						interestRate: 5 }),
			},
		};
	}
	case 'financing/setSoftLoanName': {
		const softLoanEdited = {
			name: action.value,
			type: state.financingReducer.softLoans[action.name].type,
			amount: state.financingReducer.softLoans[action.name].amount,
			redemptionPercentage: state.financingReducer.softLoans[action.name].redemptionPercentage,
			years: state.financingReducer.softLoans[action.name].years,
			interestRate: state.financingReducer.softLoans[action.name].interestRate };
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: [
					...state.financingReducer.softLoans.map((sl, idx) => (idx == action.name ? softLoanEdited : sl)),
				],
			},
		};
	}
	case 'financing/setSoftLoanType': {
		state.financingReducer.softLoans[action.name] = {
			name: state.financingReducer.softLoans[action.name].name,
			type: action.value,
			amount: state.financingReducer.softLoans[action.name].amount,
			redemptionPercentage: state.financingReducer.softLoans[action.name].redemptionPercentage,
			years: state.financingReducer.softLoans[action.name].years,
			interestRate: state.financingReducer.softLoans[action.name].interestRate };
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: state.financingReducer.softLoans,
			},
		};
	}
	case 'financing/setSoftLoanAmount': {
		state.financingReducer.softLoans[action.name] = {
			name: state.financingReducer.softLoans[action.name].name,
			type: state.financingReducer.softLoans[action.name].type,
			amount: action.value,
			redemptionPercentage: state.financingReducer.softLoans[action.name].redemptionPercentage,
			years: state.financingReducer.softLoans[action.name].years,
			interestRate: state.financingReducer.softLoans[action.name].interestRate };
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: state.financingReducer.softLoans,
			},
		};
	}
	case 'financing/setSoftLoanRedemptionPercentage': {
		state.financingReducer.softLoans[action.name] = {
			name: state.financingReducer.softLoans[action.name].name,
			type: state.financingReducer.softLoans[action.name].type,
			amount: state.financingReducer.softLoans[action.name].amount,
			redemptionPercentage: action.value,
			years: state.financingReducer.softLoans[action.name].years,
			interestRate: state.financingReducer.softLoans[action.name].interestRate };
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: state.financingReducer.softLoans,
			},
		};
	}
	case 'financing/setSoftLoanYears': {
		state.financingReducer.softLoans[action.name] = {
			name: state.financingReducer.softLoans[action.name].name,
			type: state.financingReducer.softLoans[action.name].type,
			amount: state.financingReducer.softLoans[action.name].amount,
			redemptionPercentage: state.financingReducer.softLoans[action.name].redemptionPercentage,
			years: action.value,
			interestRate: state.financingReducer.softLoans[action.name].interestRate };
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: state.financingReducer.softLoans,
			},
		};
	}
	case 'financing/setSoftLoanInterestRate': {
		state.financingReducer.softLoans[action.name] = {
			name: state.financingReducer.softLoans[action.name].name,
			type: state.financingReducer.softLoans[action.name].type,
			amount: state.financingReducer.softLoans[action.name].amount,
			redemptionPercentage: state.financingReducer.softLoans[action.name].redemptionPercentage,
			years: state.financingReducer.softLoans[action.name].years,
			interestRate: action.value };
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: state.financingReducer.softLoans,
			},
		};
	}
	case 'financing/deleteSoftLoan': {
		state.financingReducer.softLoans.splice(action.name, 1);
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: state.financingReducer.softLoans,
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const FinancingType = {
	annuity: 'annuity',
	linear: 'linear',
};

export const initialFinancingReducerState = {
	financingReducer: {
		giftsAndSubsidies: 0,
		buildingLoan: {
			gracePeriod: 2,
		},
		mortgage: {
			type: FinancingType.linear,
			percentage: 40, // of rental houses
			redemptionPercentage: 50,
			interestRate: 5, // in %
			refinanceAfter: 10, // years
			interestRateAfterRefinance: 5, // in %
			years: 20, // years
			// periods: 30,
		},
		softLoans: [ {
			name: 'Nieuwe lening',
			type: FinancingType.linear,
			amount: 0,
			redemptionPercentage: 100,
			years: incomeReducer.explotationTermPeriodYears,
			// periods: 30,
			interestRate: 5, // in %
		} ],
	},
};

export const financing = (state) => state.financingReducer;

export const capitalRequired = createSelector(
	totalBuyCostsSocialAndFreeSectorOnly,
	totalBuyCostsSocialAndFreeSectorOnly => totalBuyCostsSocialAndFreeSectorOnly,
);

export const giftsAndSubsidies = createSelector(
	financing,
	financing => financing.giftsAndSubsidies,
);


export const totalMortgage = createSelector(
	financing,
	capitalRequired,
	(financing, capitalRequired) => {
		return (financing.mortgage.percentage / 100) * capitalRequired;
	}
);

export const totalSoftLoan = createSelector(
	financing,
	(financing) => {
		return financing.softLoans.reduce((sum, softLoan) => sum + softLoan.amount, 0);
	}
);

export const financingGap = createSelector(
	giftsAndSubsidies,
	totalMortgage,
	totalSoftLoan,
	capitalRequired,
	(giftsAndSubsidies, totalMortgage, totalSoftLoan, capitalRequired) => {
		return capitalRequired - totalMortgage - totalSoftLoan - giftsAndSubsidies;
	}
);

// const inbreng = totalToFinance - mortgageTotal - softLoanTotal;
export const firstYearCapitalRequirement = createSelector(
	giftsAndSubsidies,
	totalMortgage,
	totalSoftLoan,
	financingGap,
	(giftsAndSubsidies, totalMortgage, totalSoftLoan, financingGap) => {
		return giftsAndSubsidies + financingGap + totalMortgage + totalSoftLoan;
	}
);

export const loanToValue = createSelector(totalMortgage, value, housing, (totalMortgage, value, housing) => {
	return value.VONFreeSectorPerM2 == 0 ? 0 : totalMortgage /
		(value.VONFreeSectorPerM2 * housing.noHouses.freeSector * housing.areaVvo.freeSector);
});
