import React, { useContext } from 'react';
import ShowConditional from '../../lib/ShowConditional';
import IndicatorPill from './IndicatorPill';
import { irr } from 'financial';
import { financing, loanToValue } from '../../../reducers/financingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import {
	totalIncomeFirstYear,
} from '../../../reducers/incomeReducer';
import { cashFlowFollowingYearsNew, netIncomeForYearY } from '../../../reducers/calculations/cashFlowNew';
import { buildCostsFreeSectorTotal, totalBuyCosts } from '../../../reducers/investingReducer';
import {
	mortgageCostsSecondYear,
	mortgageRepaymentSecondYear,
	softLoanRepaymentSecondYear,
} from '../../../reducers/calculations/loanCosts';

export default function Indicators() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const result = cashFlowFollowingYearsNew(state);

	const firstYearUnderZero = result.findIndex((x) => x.total < 0);
	const terugVerdienTijd = result.findIndex((x) => x.totalWithoutFinancing >= 0);


	return <div className="indicator-section">
		<h2>Indicatoren</h2>

		<ShowConditional condition={state.housingReducer.noHouses.social + state.housingReducer.noHouses.freeSector > 0}>
			<h3>Effectief Rendement</h3>

			<div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>

				<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
					<div className="indicator-unit">
						<IndicatorPill
							error={(x) => x < 1.5 || x === Number.POSITIVE_INFINITY || x === Number.NEGATIVE_INFINITY}
							warn={(x) => x >= 1.5 && x < 2.5}
							ok={(x) => x >= 2.5}
							value={irr(result.map((year) => year.result)) * 100}
							suffix='%'
							infoError='Minder dan 1,5%'
							infoWarn='Tussen 1,5% en 2,5%'
							infoOk='Meer dan 2,5%' />
						<span className="indicator-caption">Gebouw</span>
					</div>
				</ShowConditional>
			</div>
		</ShowConditional>

		<ShowConditional condition={state.housingReducer.noHouses.social + state.housingReducer.noHouses.freeSector > 0}>
			<div className='mt-6'>
				<h3>Bruto aanvangsrendement</h3>

				<div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>

					<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
						<div className="indicator-unit">
							<IndicatorPill
								error={(x) => x < 1.5}
								warn={(x) => x >= 1.5 && x < 2.5}
								ok={(x) => x >= 2.5}
								value={(netIncomeForYearY(state, 0) / buildCostsFreeSectorTotal(state)) * 100}
								suffix='%'
								infoError='Minder dan 1,5%'
								infoWarn='Tussen 1,5% en 2,5%'
								infoOk='Meer dan 2,5%'
							/>
							<span className="indicator-caption">Gebouw</span>
						</div>
					</ShowConditional>
				</div>
			</div>
		</ShowConditional>

		<div className="mt-6">
			<h3>Terugverdientijd</h3>
			<div className="indicator-unit">
				<IndicatorPill
					error={(x) => x > 30}
					warn={(x) => x >= 25 && x < 30}
					ok={(x) => x < 25}
					decimals={0}
					suffix=" jaar"
					value={terugVerdienTijd >= 0 ? terugVerdienTijd : NaN}
					infoError='Meer dan 30 jaar'
					infoWarn='Tussen 25 en 30 jaar'
					infoOk='Minder dan 25 jaar' />
			</div>
		</div>

		<div className= "mt-6">
			<h3>Solvabiliteit</h3>
			<div className="indicator-unit">
				<IndicatorPill
					error={(x) => firstYearUnderZero >= 0}
					warn={(x) => false}
					ok={(x) => firstYearUnderZero < 0}
					suffix={firstYearUnderZero < 0 ? '' : ' jaar'}
					value={firstYearUnderZero < 0 ? <>&nbsp;Ok</> : <>&nbsp;Niet ok</>}
					nanAllowed={true}
					rawValue={true} />
			</div>
			{firstYearUnderZero > -1 && <div className="indicator-caption" style={{ marginTop: 'var(--u-4)' }}>Het project is met de huidige financiering insolvent na {firstYearUnderZero} jaar</div>}
		</div>

		<div className='mt-6'>
			<h3>Leninglastendekking</h3>
			<div className="indicator-unit">
				<IndicatorPill
					error={(x) => x < 0.8}
					warn={(x) => x >= 0.8 && x < 1.2}
					ok={(x) => x >= 1.2}
					value={
						(result[1].total /
						(mortgageCostsSecondYear(state) +
							financing(state).softLoans.reduce((sum, sl, idx) => sum + (sl.interestRate / 100) * sl.amount, 0) +
							softLoanRepaymentSecondYear(state) +
							mortgageRepaymentSecondYear(state)))
					}
					infoError='Minder dan 0,8'
					infoWarn='Tussen 0,8 en 1,2'
					infoOk='Meer dan 1,2' />
			</div>
		</div>

		<div className='mt-6'>
			<h3>Waardeverhouding (LtV)</h3>
			<div className="indicator-unit">
				<IndicatorPill
					error={(x) => x > 80}
					warn={(x) => x >= 70 && x <= 80}
					ok={(x) => x < 70}
					value={loanToValue(state) != undefined ? loanToValue(state) * 100 : 0}
					decimals={1}
					suffix='%'
					infoError='Meer dan 80'
					infoWarn='Tussen 70 en 80'
					infoOk='Minder dan 70' />
				<span className="indicator-caption">Hypotheek</span>
			</div>
		</div>
	</div>;
}