import { createSelector } from 'reselect';

import { bvoToVvoMultiplier } from './StepsStoreProvider';

export function housingReducer(state, action) {
	switch (action.type) {
	case 'housing/setMunicipality': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				municipality: action.municipality,
			},
		};
	}
	case 'housing/noHouses': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				noHouses: {
					...state.housingReducer.noHouses,
					[action.name]: action.value,
				},
			},
		};
	}
	case 'housing/areaBvo': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				areaBvo: {
					...state.housingReducer.areaBvo,
					[action.name]: action.value,
				},
				areaVvo: {
					...state.housingReducer.areaVvo,
					[action.name]: action.value * bvoToVvoMultiplier,
				},
			},
		};
	}
	case 'housing/areaVvo': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				areaVvo: {
					...state.housingReducer.areaVvo,
					[action.name]: action.value,
				},
			},
		};
	}
	case 'housing/rentableRoomAdd': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				rentableRooms: state.housingReducer.rentableRooms.concat({
					name: 'Nieuwe ruimte',
					GO: action.value,
					yearlyContractIncome: 0,
					amountOfHoursFullPrice: 0,
					amountOfHoursReducedPrice1: 0,
					amountOfHoursReducedPrice2: 0,
					amountOfDayPartsFullPrice: 0,
					amountOfDayPartsReducedPrice: 0,
					pricePerHourFull: 0,
					pricePerHourReduced1: 0,
					pricePerHourReduced2: 0,
					pricePerDayPartFull: 80,
					pricePerDayPartReduced: 40,
				}),
			},
		};
	}
	case 'housing/rentableRoomChangeName': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				rentableRooms: state.housingReducer.rentableRooms.map((r, idx) => (idx == action.name ? { ...r, name: action.value } : r)),
			},
		};
	}
	case 'housing/rentableRoomPropertySet': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				rentableRooms: state.housingReducer.rentableRooms.map((r, idx) => (idx == action.index ? { ...r, [action.name]: action.value } : r)),
			},
		};
	}
	case 'housing/rentableRoomChangeGO': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				rentableRooms: state.housingReducer.rentableRooms.map((r, idx) => (idx == action.name ? { ...r, GO: action.value } : r)),
			},
		};
	}
	case 'housing/rentableRoomDelete': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				rentableRooms: state.housingReducer.rentableRooms.filter((_, idx) => idx != action.name),
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const initialHousingReducerState = {
	housingReducer: {
		municipality: null,
		noHouses: {
			social: 0,
			freeSector: 1,
			buy: 0,
		},
		areaBvo: {
			social: 0,
			freeSector: 80,
			buy: 0,
		},

		areaVvo: {
			social: 0,
			freeSector: 64,
			buy: 0,
		},
		rentableRooms: [
			{
				name: 'Vergaderruimte',
				GO: 0,
				yearlyContractIncome: 0,
				amountOfHoursFullPrice: 0,
				amountOfHoursReducedPrice1: 0,
				amountOfHoursReducedPrice2: 0,
				amountOfDayPartsFullPrice: 0,
				amountOfDayPartsReducedPrice: 0,
				pricePerHourFull: 0,
				pricePerHourReduced1: 0,
				pricePerHourReduced2: 0,
				pricePerDayPartFull: 80,
				pricePerDayPartReduced: 40,
			},
		],
	},
};

export const housing = (state) => state.housingReducer;
export const building = (state) => state.buildingReducer;

export const noHouses = (state) => state.housingReducer.noHouses;
export const totalNoOfHouses = createSelector(noHouses, (noHouses) => Object.values(noHouses).reduce((tot, moreHouses) => tot + moreHouses, 0)
);

export const areaBvo = (state) => state.housingReducer.areaBvo;
export const totalHousesAreaBvo = createSelector(noHouses, areaBvo, (noHouses, areaBvo) => Object.keys(areaBvo).reduce((tot, key) => tot + noHouses[key] * areaBvo[key], 0)
);


export const areaVvo = (state) => state.housingReducer.areaVvo;
export const totalSocialHousesAreaVvo = createSelector(
	noHouses,
	areaVvo,
	(noHouses, areaVvo) => noHouses.social * areaVvo.social
);

export const totalHousesAreaVvo = createSelector(noHouses, areaVvo, (noHouses, areaVvo) => Object.keys(areaVvo).reduce((tot, key) => tot + noHouses[key] * areaVvo[key], 0)
);

export const rentableRoomsTotalGO = (state) => state.housingReducer.rentableRooms.reduce((sum, i) => sum + ((i.GO == null || i.GO == '') ? 0 : i.GO), 0);
export const rentableRoomsTotal = (state) => state.housingReducer.rentableRooms.length;

export const vormRatio = createSelector(
	totalHousesAreaBvo,
	totalHousesAreaVvo,
	(totalHousesAreaBvo, totalHousesAreaVvo) => totalHousesAreaVvo / totalHousesAreaBvo
);

export const totalUsableSpace = createSelector(building, rentableRoomsTotalGO, (building, rentableRoomsTotalGO) => building.commonAreaVvo + rentableRoomsTotalGO);
