import { pmt } from 'financial';
import { createSelector } from 'reselect';

import { financing, FinancingType, totalMortgage, totalSoftLoan } from '../financingReducer';

export const mortgageCostsSecondYear = createSelector(financing, totalMortgage, (financing, totalMortgage) => {
	return (financing.mortgage.interestRate / 100) * totalMortgage;
});

export const mortgageAnnuity = createSelector(financing, totalMortgage, (financing, totalMortgage) => {
	return financing.mortgage.type === FinancingType.annuity
		? -pmt(
			financing.mortgage.interestRate / 100,
			financing.mortgage.years,
			(financing.mortgage.redemptionPercentage / 100) * totalMortgage,
			1 - financing.mortgage.redemptionPercentage / 100
		  )
		: undefined;
});

export const mortgageRepaymentSecondYear = createSelector(
	financing,
	totalMortgage,
	mortgageAnnuity,
	mortgageCostsSecondYear,
	(financing, totalMortgage, mortgageAnnuity, mortgageCostsSecondYear) => {
		if (financing.mortgage.type === FinancingType.annuity) {
			return mortgageAnnuity - mortgageCostsSecondYear;
		}

		return (totalMortgage * (financing.mortgage.redemptionPercentage / 100)) / financing.mortgage.years;
	}
);

export const softLoanCostsSecondYear = createSelector(financing, totalSoftLoan, (financing, totalSoftLoan) => {
	return (financing.softLoans.interestRate / 100) * totalSoftLoan;
});

export const softLoanAnnuity = createSelector(financing, totalSoftLoan, (financing, totalSoftLoan) => {
	return financing.softLoans.type === FinancingType.annuity
		? -pmt(
			financing.softLoans.interestRate / 100,
			financing.softLoans.years,
			(financing.softLoans.redemptionPercentage / 100) * totalSoftLoan,
			1 - financing.softLoans.redemptionPercentage / 100
		  )
		: undefined;
});

export const softLoanRepaymentSecondYear = createSelector(
	financing,
	totalSoftLoan,
	softLoanAnnuity,
	softLoanCostsSecondYear,
	(financing, totalSoftLoan, softLoanAnnuity, softLoanCostsSecondYear) => {
		return financing.softLoans.reduce((sum, i) => (totalSoftLoan * (i.redemptionPercentage / 100)) / i.years, 0);
	}
);

export const totalLoanCostsSecondYear = createSelector(
	mortgageCostsSecondYear,
	softLoanCostsSecondYear,
	mortgageRepaymentSecondYear,
	softLoanRepaymentSecondYear,
	(mortgageCostsSecondYear, softLoanCostsSecondYear, mortgageRepaymentSecondYear, softLoanRepaymentSecondYear) => {
		return (
			mortgageCostsSecondYear +
			softLoanCostsSecondYear +
			mortgageRepaymentSecondYear +
			softLoanRepaymentSecondYear
		);
	}
);
